<div fxLayout="column" [ngStyle]="{'--entity-color' : uiData.atp.color}">
    <mat-card [style.background-color]="uiData.atp.color">
        <mat-card-title>
            {{courseData?.id === 0 ? 'Add New Course' : 'Edit Course ' + courseData?.id + ': ' + courseData?.accreditationDiscipline}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">
        <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
            <mat-tab>
                <ng-template mat-tab-label>                
                    Course Information
                </ng-template>
                <ng-container>
                    <form [formGroup]="courseDataForm" fxLayout="column">
                        <div fxLayout="row" fxLayout.lt-md="column">
                            <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                                <mat-card-content>                                                    
                                    <div fxLayout="column" fxFlex="100">                                     
                                        <mat-checkbox class="mat-checkbox" [checked]="courseDataForm.get('receivedPreNote').value" formControlName="receivedPreNote" (change)="updatePreNoteDateDisabledStatus($event)">
                                            <span class="mat-checkbox-label">
                                                <strong>Pre-Training Notification Received</strong>
                                            </span>
                                        </mat-checkbox>
            
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="preDatePicker" placeholder="Date Received" formControlName="receivedPreNoteDate" (focus)="preDatePicker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="preDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #preDatePicker></mat-datepicker>
                                        </mat-form-field>                                                                      
                                                                    
                                        <span><strong>Course Discipline</strong></span>                           
                                        <mat-form-field>
                                            <mat-label>Course Discipline</mat-label>
                                            <mat-select  formControlName="accreditationDiscipline">   
                                                <mat-option *ngFor="let status of getCourseDisciplines()" [value]="status">{{ status }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
            
                                        <span><strong>Course Type</strong></span>
                                        <mat-form-field>
                                            <mat-label>Course Type</mat-label>                                
                                            <mat-select formControlName="stage">   
                                                <mat-option *ngFor="let status of getCourseTypes()" [value]="status">{{ status }}</mat-option>                                                                                                                                         
                                            </mat-select>
                                        </mat-form-field>
            
                                        <span><strong>Course Language</strong></span>
                                        <mat-form-field>
                                            <mat-label>Course Language</mat-label>                                    
                                            <mat-select formControlName="accreditationCourseLanguage">   
                                                <mat-option *ngFor="let status of getCourseLanguages()" [value]="status">{{ status }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
            
                                        <span><strong>Course Format</strong></span>
                                        <mat-form-field>
                                            <mat-label>Course Format</mat-label>
                                            <mat-select formControlName="accreditationCourseFormat">   
                                                <mat-option *ngFor="let status of getCourseFormats()" [value]="status">{{ status }}</mat-option>                                                                                                            
                                            </mat-select>
                                        </mat-form-field>
            
                                        <mat-checkbox class="mat-checkbox" [checked]="courseDataForm.get('isPrivate').value" formControlName="isPrivate">
                                            <span class="mat-checkbox-label">
                                                <strong>Private Course</strong>
                                            </span>
                                        </mat-checkbox>                                            
                                    </div>                   
                                </mat-card-content>
                            </mat-card>
                            &nbsp;&nbsp;
                            <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                                <mat-card-content>    
                                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="100"> 
                                        <span><strong>Course Start</strong></span>
                                        <div fxLayout="row">
                                            <div style="margin-right: 10px; width: 50%;" fxFlex="100">
                                                <mat-form-field style="width: 100%;">
                                                    <input matInput [matDatepicker]="startDateTimePicker" placeholder="Start Date" formControlName="courseStart" (focus)="startDateTimePicker.open()">
                                                    <mat-datepicker-toggle matSuffix [for]="startDateTimePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #startDateTimePicker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                            <div style="width: 50%;" fxFlex="100">
                                                <mat-form-field class="time-input-field" style="width: 100%;">
                                                    <input matInput type="time" placeholder="Start Time" formControlName="courseStartTime">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        
                                        <span><strong>Course End</strong></span>
                                        <div fxLayout="row">
                                            <div style="margin-right: 10px; width: 50%;" fxFlex="100">
                                                <mat-form-field style="width: 100%;">
                                                    <input matInput [matDatepicker]="endDateTimePicker" placeholder="End Date" formControlName="courseEnd" (focus)="endDateTimePicker.open()">
                                                    <mat-datepicker-toggle matSuffix [for]="endDateTimePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #endDateTimePicker></mat-datepicker>
                                                </mat-form-field>   
                                            </div>                               
                                            <div style="width: 50%;" fxFlex="100">
                                                <mat-form-field class="time-input-field" style="width: 100%;">
                                                    <input matInput type="time" placeholder="End Time" formControlName="courseEndTime">
                                                </mat-form-field>  
                                            </div>    
                                        </div>                   
            
                                        <div fxLayout="column" class="m-t-8">
                                            <span><strong>Course Address</strong></span>           
                                            <mat-form-field fxFlex="100" fxFlex.lt-md="100">
                                                <input matInput placeholder="Location Name" formControlName="locationName">
                                            </mat-form-field>
                                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                                <mat-form-field fxFlex="70" fxFlex.lt-md="100">
                                                    <input matInput placeholder="Street Apt/Unit/Suite" formControlName="street">
                                                </mat-form-field>
                                                    
                                                <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                                                    <input matInput placeholder="City"  formControlName="city">
                                                </mat-form-field>                
                                            </div>               
                                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                                <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                                                    <mat-label>State</mat-label>
                                                    <mat-select formControlName="state">
                                                    <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                                    </mat-select>                                
                                                </mat-form-field>
                                
                                                <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                                    <input matInput placeholder="Zip" formControlName="zip">
                                                </mat-form-field>
                                
                                                <mat-form-field fxFlex="70" fxFlex.lt-md="100" *ngIf="courseDataForm.get('state').value === 'OR'">                                                                    
                                                    <mat-label>County</mat-label>
                                                    <mat-select formControlName="county" >
                                                    <mat-option>-- None --</mat-option>
                                                    <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                                                    </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div> 
                                        </div> 
                                        
                                        <div fxLayout="column" class="m-t-8">
                                            <span><strong>Course Instructor</strong></span>  
                                            <mat-form-field>
                                                <mat-label>Select Staff</mat-label>
                                                <input matInput type="text" placeholder="Type to filter" formControlName="programInstructor" [matAutocomplete]="auto" name="programInstructor" (input)="filterPeople($event)">
                                                <mat-autocomplete #auto="matAutocomplete">
                                                  <mat-option *ngFor="let person of filteredPersonList" [value]="person.firstName + ' ' + person.lastName">{{person.firstName + ' ' + person.lastName}}</mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
            
                                        <div fxLayout="row" fxLayout.lt-md="column">
                                            <mat-form-field fxFlex="30" fxFlex.lt-md="100">
                                                <input matInput placeholder="Instructor Phone" formControlName="programInstructorPhone">
                                            </mat-form-field>
                                            &nbsp;&nbsp;
                                            <mat-form-field fxFlex="70" fxFlex.lt-md="100">
                                                <input matInput placeholder="Instructor Email" formControlName="programInstructorEmail">
                                            </mat-form-field>
                                        </div>
                                    </div>                                  
                                </mat-card-content>
                            </mat-card>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column">
                            <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                                <mat-card-content>    
                                    <div fxLayout="column" fxFlex="100">                                     
                                        <mat-checkbox class="mat-checkbox" [checked]="courseDataForm.get('receivedPostNote').value" formControlName="receivedPostNote" (change)="updatePostNoteDateDisabledStatus($event)">
                                            <span class="mat-checkbox-label">
                                                <strong>Post-Training Notification Received</strong>
                                            </span>
                                        </mat-checkbox>
            
                                        <mat-form-field>
                                            <input matInput [matDatepicker]="postDatePicker" placeholder="Date Received" formControlName="receivedPostNoteDate" (focus)="postDatePicker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="postDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #postDatePicker></mat-datepicker>
                                        </mat-form-field>                                                                                                                                                                                  
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            &nbsp;&nbsp;
                            <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                                <mat-card-content>    
                                    <mat-checkbox class="mat-checkbox" [checked]="courseDataForm.get('cancelled').value" formControlName="cancelled">
                                        <span class="mat-checkbox-label">
                                            <strong>Course Cancelled</strong>
                                        </span>
                                    </mat-checkbox>
            
                                    <mat-checkbox class="mat-checkbox" [checked]="courseDataForm.get('receivedStudentFees').value" formControlName="receivedStudentFees">
                                        <span class="mat-checkbox-label">
                                            <strong>Student Fees Received</strong>
                                        </span>
                                    </mat-checkbox>
                                    &nbsp;
                                    <div fxLayout="column">
                                        <span><strong>Total Students</strong></span>
                                        <mat-form-field>
                                          <input matInput placeholder="Total Students" [value]="tableData?.length" disabled>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="column">
                                        <span><strong>Control Number</strong></span>                           
                                        <mat-form-field>
                                            <input matInput placeholder="Control Number" formControlName="controlNumber">
                                        </mat-form-field>
                                    </div>                                    
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </form>
                </ng-container>
            </mat-tab>

            <mat-tab *ngIf="courseData?.id !== 0">
                <ng-template mat-tab-label>                
                    Student Roster ({{tableData.length}})
                </ng-template>
                <ng-container>  
                    <mat-card>
                        <mat-card-content>
                            <div fxLayout="column">
                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                        <div>
                                            <strong class="noMarginHeader">Results ({{tableData.length}})</strong> 
                                            &nbsp;&nbsp;&nbsp;&nbsp;     
                                            <mat-form-field style="width:200px;">
                                                <input (keyup)="filterTable()" placeholder="Filter Results" [(ngModel)]="filterTableString" matInput/>                            
                                            </mat-form-field>      
                                        </div>                                     
                                        <div>                        
                                            <button mat-raised-button color="primary" (click)="editTableEntry(null)" *ngIf="courseData?.id !== 0">
                                                <mat-icon>add</mat-icon> Add Student
                                            </button>                                            
                                        </div>
                                    </div>
                
                                    <br />

                                    <mat-table [dataSource]="tableDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="tableColumns"></mat-header-row>                  
                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: tableColumns" (click)="editTableEntry(row); $event.stopPropagation()"></mat-row>                                      
                                                            
                                        <ng-container *ngFor="let cellData of tableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                            <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                <ng-container [ngSwitch]="cellData.type">
                                                    <ng-container *ngSwitchCase="'date'">
                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'bool'">
                                                        <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                    </ng-container>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container> 

                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef fxFlex="10">Actions</mat-header-cell>
                                            <mat-cell *matCellDef="let row" fxFlex="10">
                                                <div style="display: flex; justify-content: space-between; width: 100px;">
                                                    <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteStudent(row); $event.stopPropagation()">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                    </mat-table>
                                                    
                                    <br />
                                    <mat-paginator [pageSize]="tablePageSize" [pageSizeOptions]="[5, 10, 50, tableData.length]" #TablePaginator="matPaginator"
                                        showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>                        
                </ng-container>
            </mat-tab>

            <!--Course Payments-->
            <mat-tab *ngIf="courseData?.id !== 0">
                <ng-template mat-tab-label>                
                    Payments 
                </ng-template>
                <ng-container>  
                    <div *ngIf="courseData?.id === 0" class="text-red-400"><strong><br />The Course Needs to be Saved first.</strong></div>
                    <div fxLayout="column" fxLayoutGap="8px" *ngIf="courseData?.id > 0">
                        <app-manage-payments
                                   [entityId]="courseData?.id.toString()"
                                   [entityType]="paymentService.AtpCourseEntityType"
                                   [allowMultiplePayments]='true'
                                   [editingPayment]="canEdit"
                                   (notifyParent)="paymentUpdated($event)"
                                   [allowDelete]='true'
                                   [cardColor]="cardColor"
                                   [changeIndicator]="changeIndicator">
              </app-manage-payments>                
                    </div>                        
                </ng-container>
            </mat-tab>
        </mat-tab-group>        
    </div>
    <mat-card [style.background-color]="uiData.atp.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{courseData?.id === 0 ? 'Add' : 'Save'}}
        </button>
        <button mat-raised-button color="primary" aria-label="Save" *ngIf="courseData?.id !== 0" (click)="uploadRoster()">
            <mat-icon>upload</mat-icon> Roster
        </button>
        <button mat-raised-button [color]="courseData?.cancelled ? 'primary' : 'warn'" aria-label="Cancel" *ngIf="courseData?.id !== 0" (click)="cancelCourse(!courseData?.cancelled)">
            {{ courseData?.cancelled ? 'Restore' : 'Cancel'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>
