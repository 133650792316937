import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { IComplaint } from "app/models/complaint/complaint";
import { IComplaintAction } from "app/models/complaint/complaintAction";
import { IComplaintAssociation } from "app/models/complaint/complaintAssociation";
import { IComplaintInspection } from "app/models/complaint/complaintInspection";
import { IComplaintOwnerTenant } from "app/models/complaint/complaintOwnerTenant";
import { IComplaintReferral } from "app/models/complaint/complaintReferral";
import { ComplaintService } from "app/services/complaint/complaint.service";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-print-complaint',
  templateUrl: './print-complaint.component.html',
  styleUrls: ['./print-complaint.component.scss']
})

export class PrintComplaintComponent implements OnInit {
    public isMobile: boolean = false;
    public complaintId : number;
    public complaintJurisdictions: string;
    public complaintEnforcementCategory: string;
    enforcementCategoryOptions = [
        {name:'Pre-renovation Education', value:'prerenovation-education', checked:false},
        {name:'Prohibited Activites', value:'prohibited-activites', checked:false},
        {name:'Containment', value:'containment', checked:false},
        {name:'Cleanup/Verification', value:'cleanup-verification', checked:false},
        {name:'Waste Containment/Disposal', value:'wastecontainment-disposal', checked:false},
        {name:'Documentation/Recordkeeping', value:'documentation-recordkeeping', checked:false}
      ];
      jurisdictionOptions = [
        {name:'CCB', value:'CCB', checked:false},
        {name:'HUD', value:'HUD', checked:false},
        {name:'OSHA', value:'OSHA', checked:false},
        {name:'EPA', value:'EPA', checked:false},
        {name:'DEQ', value:'DEQ', checked:false},
        {name:'OHA', value:'OHA', checked:false},
        {name:'Local', value:'Local', checked:false}
      ]
  public complaint: IComplaint = {
    id: 0,
    complainantName: "",
    complainteeName: "",
    complaintProblem: "",
    complaintNumber: "",
    status: "",
    complaintClosedDate: new Date(),
    complainteeFirstName: "",
    complainteeLastName: "",
    complainteePhone: "",
    complainteeEmail: "",
    complainteeAddress: "",
    complainteeCity: "",
    complainteeState: "",
    complainteeZip: "",
    complainteeEmployer: "",
    complaintDate: undefined,
    isAnyonymous: false,
    lastUpdatedBy: "",
    lastUpdated: undefined,
    complaintAssociationName: "",
    complaintContractor1: "",
    complaintContractor2: "",
    complaintContractor3: "",
    contractorCCB1: "",
    contractorCCB2: "",
    contractorCCB3: "",
    otherWorker: "",
    complainantAddress: "",
    complainantCity: "",
    complainantState: "",
    complainantZip: "",
    complainantCounty: "",
    complaintInvestigator: "",
    investigatorAssignedDate: "",
    complaintAssociations: [],
    associationTypes: [],
    complaintTargetPhone: "",
    complaintTargetEmail: ""
  };

  public complaintReferrals: IComplaintReferral[] = [];
  public complaintReferralTable = 
  {
    layout:{
      columns:['complaintNumber','firstName','lastName','email','agencyName','agencyType','referralDate','agencyEmail','phoneNumber','fax'],
      container:[
        {displayName:'Complaint Number',columnName:'complaintNumber', type:'string', size:'15'},        
        {displayName:'First Name',columnName:'firstName', type:'string', size:'25'},
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'55'},
        {displayName:'Email',columnName:'email', type:'string', size:'20'},        
        {displayName:'Agency Name',columnName:'agencyName', type:'string', size:'25'},
        {displayName:'Agency Type',columnName:'agencyType', type:'string', size:'55'},
        {displayName:'Referral Date',columnName:'referralDate', type:'string', size:'20'},  
        {displayName:'Agency Email',columnName:'agencyEmail', type:'string', size:'20'},
        {displayName:'Phone',columnName:'phoneNumber', type:'string', size:'20'},        
        {displayName:'Fax',columnName:'fax', type:'string', size:'25'}               
      ],
      data: []      
    }
  };
  public complaintReferralsDataSource: MatTableDataSource<any>;
  public complaintReferralTablePageSize = 5;

  public complaintInspections: IComplaintInspection[] = [];
  public complaintInspectionTable = 
  {
    layout:{
      columns:['inspectionDate','inspectionType','scope','inspectionDetails'],
      container:[
        {displayName:'Date of Inspection',columnName:'inspectionDate', type:'date', size:'20'},        
        {displayName:'Inspection Type',columnName:'inspectionType', type:'string', size:'25'},
        {displayName:'Scope',columnName:'scope', type:'string', size:'25'},
        {displayName:'Inspection Details',columnName:'inspectionDetails', type:'string', size:'35'}                
      ],
      data: []      
    }
  };
  public complaintInspectionDataSource: MatTableDataSource<any>;
  public complaintInspectionTablePageSize = 5;

  public complaintActions: IComplaintAction[] = [];
  public complaintActionTable = 
  {
    layout:{
      columns:['actionDate','actionType'],
      container:[
        {displayName:'Date of Action',columnName:'actionDate', type:'date', size:'20'},        
        {displayName:'Type of Action',columnName:'actionType', type:'string', size:'25'}                
      ],
      data: []      
    }
  };
  public complaintActionDataSource: MatTableDataSource<any>;
  public complaintActionTablePageSize = 5;

  public complaintOwnerTenants: IComplaintOwnerTenant[] = [];
  public complaintOwnerTenantTable = 
  {
    layout:{
      columns:['isOwner','firstName','lastName','email','phoneNumber','address','city','state','zip','cellNumber','fax'],
      container:[
        {displayName:'Is Owner',columnName:'isOwner', type:'bool', size:'5'},        
        {displayName:'First Name',columnName:'firstName', type:'string', size:'25'},
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'55'},
        {displayName:'Email',columnName:'email', type:'string', size:'20'},        
        {displayName:'Phone',columnName:'phoneNumber', type:'string', size:'25'},
        {displayName:'Address',columnName:'address', type:'string', size:'55'},
        {displayName:'City',columnName:'city', type:'string', size:'20'},        
        {displayName:'State',columnName:'state', type:'string', size:'5'},
        {displayName:'Zip',columnName:'zip', type:'string', size:'20'},
        {displayName:'Cell',columnName:'cellNumber', type:'string', size:'20'},        
        {displayName:'Fax',columnName:'fax', type:'string', size:'25'}            
      ],
      data: []      
    }
  };
  public complaintOwnerTenantDataSource: MatTableDataSource<any>;
  public complaintOwnerTenantTablePageSize = 5;

  public complaintAssocations: IComplaintAssociation[] = [];
  complaintAssociationTable = {
    icon: 'recent_actors',
    title:'Complaint Assocations',
    layout:{
      columns:['id', 'type', 'name'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'20'},
        {displayName:'Type',columnName:'type', type:'string', size:'25'},
        {displayName:'Name',columnName:'name', type:'string', size:'30'}       
      ],
      data: []      
    }
  }
  public complaintAssocationDataSource: MatTableDataSource<any>;
  public complaintAssocationTablePageSize = 5;

  constructor(public sharedService: SharedService,
    private breakpointObserver: BreakpointObserver,
    private complaintService: ComplaintService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<PrintComplaintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
        this.breakpointObserver.observe([
            Breakpoints.Handset,
            Breakpoints.Tablet,
            Breakpoints.Small,
          ]).subscribe(result => {
            this.isMobile = result.matches;
          });
    }

    displayLimit(text: any, limit: string){
        let newText = text;
        if (typeof text === 'string' && text.length > parseInt(limit)){
          newText = newText.substring(0, limit) + "...";
        }    
        return newText;
      }

    ngOnInit(): void {
      this.complaint = this.data.selectedComplaint;
      this.complaintId = this.complaint.id;
      if (this.complaintId !== 0){
        this.getComplaintAssociations();
        this.getComplaintReferrals();
        this.getComplaintOwnerTenants();
        this.getComplaintInspections();
        this.getComplaintActions();   
        this.getComplaintJurisdictions();
        this.getComplaintEnforcementCategories();   
      } 
    }

    cancel(): void {
      this.dialogRef.close('cancel');
    }

    getComplaintAssociations(){
        this.complaintService.getComplaintAssociations(this.complaintId).subscribe(result=>{        
          this.complaintAssocations = result;
          this.complaintAssociationTable.layout.data = this.complaintAssocations;
          this.complaintAssocationDataSource = new MatTableDataSource(this.complaintAssocations);
        },error=>{      
          this.toastr.error("There was an error getting the Complaint Associations: ", error)
        });
      }
    
      getComplaintReferrals(){
        this.complaintService.getComplaintReferrals(this.complaintId).subscribe(result=>{        
          this.complaintReferrals = result;
          this.complaintReferralTable.layout.data = this.complaintReferrals;
            this.complaintReferralsDataSource = new MatTableDataSource(this.complaintReferrals);
        },error=>{      
          this.toastr.error("There was an error getting the Complaint Referrals: ", error)
        });
      }
    
    
    
      getComplaintOwnerTenants(){
        this.complaintService.getComplaintOwnerTenants(this.complaintId).subscribe(result=>{        
          this.complaintOwnerTenants = result;
          this.complaintOwnerTenantTable.layout.data = this.complaintOwnerTenants;
            this.complaintOwnerTenantDataSource = new MatTableDataSource(this.complaintOwnerTenants);
        },error=>{
          this.toastr.error("There was an error getting the Complaint Owner Tenants: ", error)
        });
      }
    
      getComplaintJurisdictions(){
        this.complaintService.getComplaintJurisdictions(this.complaintId).subscribe(result=>{        
        
          if(result && result.length > 0){
            this.complaintJurisdictions = result.map(jurisdiction=>jurisdiction.jurisdiction).join(", ");
            
          }
        },error=>{
          this.toastr.error("There was an error getting the Complaint jurisdictions: ", error)
        });
      }
      
      getComplaintEnforcementCategories(){
        this.complaintService.getComplaintEnforcementCategories(this.complaintId).subscribe(result=>{ 
            if(result && result.length > 0){
                this.complaintEnforcementCategory = result.map(category=>category.enforcementCategory).join(", ");
          }
        },error=>{
          this.toastr.error("There was an error getting the Complaint Enforcement Categories: ", error)
        });
      }
      getComplaintActions(){
        this.complaintService.getComplaintActions(this.complaintId).subscribe(result=>{        
          this.complaintActions = result;
          this.complaintActionTable.layout.data = this.complaintActions;
        this.complaintActionDataSource = new MatTableDataSource(this.complaintActions);
        },error=>{
          this.toastr.error("There was an error getting the Complaint Actions: ", error)
        });
      }
    
      getComplaintInspections(){
        this.complaintService.getComplaintInspections(this.complaintId).subscribe(result=>{        
          this.complaintInspections = result;
          this.complaintInspectionTable.layout.data = this.complaintInspections;
            this.complaintInspectionDataSource = new MatTableDataSource(this.complaintInspections);
        },error=>{
          this.toastr.error("There was an error getting the Complaint Inspections: ", error)
        });
      }
  }
