import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ANIMATIONS, APPLICATION_STATUS, CERTIFICATE_STAGE_TYPES, CODE_TYPES, FIRM_APPLICATION_TYPES, FIRM_SERVICES, FIRM_SERVICE_FOR, LEAD_BASED_PAINT} from '@shared/utils/app-static-data';
import { IAccountAffiliation } from 'app/models/common/accountAffiliation';
import { IFirm } from 'app/models/firms/firm';
import { IFirmCertificate } from 'app/models/firms/firmCertificate';
import { IFirmApplication } from 'app/models/firms/firm-application';
import { IFirmApplicationPerson } from 'app/models/firms/firm-application-person';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { AddFirmApplicationPersonComponent } from './add-firm-application-person/add-firm-application-person.component';
import { IFirmAddress } from 'app/models/firms/firmAddress';
import { IAddress } from 'app/models/common/address';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { IPayment } from 'app/components/payments/models/payment';
import { IFirmApplicationDeficiency } from 'app/models/firms/firm-application-deficiency';
import { AddFirmApplicationDeficiencyComponent } from './add-firm-application-deficiency/add-firm-application-deficiency.component';
import { IContactInformation } from 'app/models/common/contactInformation';
import { IFirmContactInformation } from 'app/models/firms/firmContactInformation';
import { IFirmPerson } from 'app/models/firms/firmPerson';
import { IPerson } from 'app/models/People/person';
import { IPersonContactInformation } from 'app/models/People/personContactInformation';
import { PersonService } from 'app/services/person/person.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ConfirmationDialogueComponent } from '../../common/confirmation-dialogue/confirmation-dialogue.component';
import { IFirmLeadCredential } from 'app/models/firms/firmLeadCredentials';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConfirmationDialogueWithFieldComponent } from '../../common/confirmation-dialogue-with-field/confirmation-dialogue-with-field.component';
import { AddDocumentComponent } from 'app/components/common/document/add-document.component';

@Component({
  selector: 'app-add-firm-application',
  templateUrl: './add-firm-application.component.html',
  styleUrls: ['./add-firm-application.component.scss'],
  animations: ANIMATIONS
})
export class AddFirmApplicationComponent implements OnInit {
  public isMobile: boolean = false;
  public applicationDataForm = null;
  public applicationAttestationForm = null
  public cardColor: string = this.sharedService.gold + '20';
  public firmServices = FIRM_SERVICES;    
  public applicationStatus = APPLICATION_STATUS;
  public isApplicationForExistingFirm: boolean = false;
  public issueDate: string = '';

  public firmApplication: IFirmApplication;    
  public loading: boolean = true;
  public reload: boolean = false;
  public canEdit: boolean = true;
  public uiData: any = LEAD_BASED_PAINT;
  public pageSize: number = 50;      
  public applicationPersons: IFirmApplicationPerson[] = [];   
  public userID: string = '';

  public applicationDeficiencies: IFirmApplicationDeficiency[] = [];
  public filteredApplicationDeficiencies:IFirmApplicationDeficiency[] = [];
  public applicationDeficiencyStatusFilterString: FormControl = new FormControl('both');
  public applicationDeficiencyFilterString: string = '';
  private applicationDeficiencyDebounceString: Subject<string> = new Subject<string>();
  public deficiencyPageSize: number = 5;
  public displayedDeficiencyColumns: string[] = ['id', 'description', 'isResolved'];
  public displayedDeficiencyColumnsFormat = [
    {displayName:'Id',columnName:'id', type:'number', size:'10'},
    {displayName:'Description',columnName:'description', type:'string', size:'70'},
    {displayName:'Resolved',columnName:'isResolved', type:'boolean', size:'10'},
  ];
  public deficiencyDataSource: MatTableDataSource<any>;
  @ViewChild('deficiencyTablePaginator') deficiencyPaginator: MatPaginator;
  @ViewChild('deficiencyTableSort') deficiencySort: MatSort;
  
  public individualTable:{icon:string,title:string,layout:any};
  public firmApplicationPerson: IFirmPerson[] = [];
  public filteredApplicationPerson: {}[] = [];  
  public applicationPersonFilterString: string = '';
  private applicationPersonDebounceString: Subject<string> = new Subject<string>();
  public personPageSize: number = 5;  
  public personDataSource: MatTableDataSource<any>;
  public changeIndicator: number = 0;
  @ViewChild('personTablePaginator') personPaginator: MatPaginator;
  @ViewChild('personTableSort') personSort: MatSort;

  public tableApplicationDocumentsColumns = ['id', 'fileName', 'fileDescription', 'actions']
  public tableApplicationDocumentsFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'File Name',columnName:'fileName', type:'string', size:'30'},
    {displayName:'File Description',columnName:'fileDescription', type:'string', size:'40'},
  ];
  public tableApplicationDocumentsData = []
  public tableApplicationDocumentsFilteredData = []
  public tableApplicationDocumentsPageSize = 5;
  public filterDocumentString: string = '';  
  public documentDataSource: MatTableDataSource<any>;
  @ViewChild('DocumentTablePaginator') documentPaginator: MatPaginator;
  @ViewChild('DocumentTableSort') documentSort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      application: IFirmApplication, 
      edit: boolean, 
      firmData: {
        firm: IFirm, 
        mailingAddress: IAddress, 
        physicalAddress: IAddress, 
        contactInformation: IContactInformation, 
        firmCurrentCertificates: IFirmCertificate[],
        firmCertificateForRenewal: IFirmCertificate,    
        renewal: boolean,     
        firmCurrentCredentials: IFirmLeadCredential[],
        firmContactPerson: IPerson,
        firmContactPersonContactInformation: IContactInformation,
      }
    },
    private dialogRef: MatDialogRef<AddFirmApplicationComponent>,
    public sharedService: SharedService,
    public firmService: FirmService,
    private personService: PersonService,
    public userService: UserInformationService,
    public commonService: CommonDataService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public paymentService: PaymentService,
    private breakpointObserver: BreakpointObserver,
  ) {     
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.isApplicationForExistingFirm = this.data?.firmData?.firm !== undefined || (this.data?.application?.firm !== undefined && this.data?.application?.firm !== null);
    this.initializeDebounceFunctions();
  }

  ngOnInit(): void {
    this.setApplicationDataFormFields();
    this.loadData();
  }

  initializeDebounceFunctions(): void {
    this.applicationDeficiencyDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.applicationDeficiencyFilterString = value.trim().toLowerCase();
      this.filterDeficiencyTable();     
    });

    this.applicationPersonDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {      
      this.applicationPersonFilterString = value.trim().toLowerCase();      
      this.filterPersonTable();     
    });
  }

  loadData(){
    this.loading = true;

    this.userService.getUserInfo().subscribe(
    res=>{
      this.userID = res.email;          
    })
    
    if(this.data.application !== null){
      this.firmService.getFirmApplicationById(this.data.application.id).subscribe(
        res=>{        
          this.firmApplication = res        
          this.canEdit = (this.firmApplication.status !== APPLICATION_STATUS.approved) && (this.data.edit)            
          this.updateIndividualTable();
          this.refreshTableApplicationDocuments();
          this.loadFormFields();

          this.updateFirmApplicationDeficiencies();
          this.loading=false;
        },
        err=>{this.toastr.error("There was an error getting the firm applicatoin: ", err)})
      
        this.firmService.getFirmApplicationPeople(this.data.application.id).subscribe(res=>{          
          this.firmApplicationPerson = res;          
          this.filterPersonTable();          
        },err=>{this.toastr.error("An error occurred getting the Firm Application People: ", err)});

        if(this.data.application.firm !== null && this.data.firmData.firm === undefined){
          this.data.firmData.firm = this.data.application.firm;

          this.firmService.getFirmLeadCredentials(this.data.application.firm.id).subscribe(res=>{
            this.data.firmData.firmCurrentCredentials = res;                    
          },err=>{this.toastr.error("An error occurred getting the Firm Credentials: ", err)});

          this.firmService.getFirmAddresses(this.data.application.firm.id).subscribe(res=>{
            this.data.firmData.physicalAddress = res.filter(x=>x.isCurrentPhysical)[0].address;
            this.data.firmData.mailingAddress = res.filter(x=>x.isCurrentMailing)[0].address;
          },err=>{this.toastr.error("An error occurred getting the Firm Addresses: ", err)});

          this.firmService.getFirmContactInformations(this.data.application.firm.id).subscribe(res=>{
            this.data.firmData.contactInformation = res[0]?.contactInformation;
          },err=>{this.toastr.error("An error occurred getting the Firm Contact Information: ", err)});

          if(this.data.application.renewal){
            this.firmService.getFirmCertificates(this.data.application.firm.id).subscribe(res=>{
              this.data.firmData.firmCertificateForRenewal = res.filter(x=>x.controlNumber === this.data.application.previousApplication)[0];
            },err=>{this.toastr.error("An error occurred getting the Firm Certificate: ", err)});
          }

          this.firmService.getFirmPeople(this.data.application.firm.id).subscribe(res=>{
            let person = res.filter(staff=>staff.isPOC)[0].person;
            this.data.firmData.firmContactPerson = person
            this.personService.getPersonContactInformationByPersonId(person.id).subscribe(res=>{
              this.data.firmData.firmContactPersonContactInformation = res?.contactInformation;
            },err=>{this.toastr.error("An error occurred getting the Firm Contact Person Contact Information: ", err)});
          },err=>{this.toastr.error("An error occurred getting the Firm People: ", err)});          
        }
    }
    else{
      const date = new Date();
      this.firmApplication = {
        id: 0,

        userID: null,
        firm: this.data.firmData?.firm ?? null,
        status: APPLICATION_STATUS.inReview,    
        applicationDate: date.toISOString(),
        applicationType: this.data?.firmData?.firmCertificateForRenewal?.codeType==='LBP Firm' ? FIRM_APPLICATION_TYPES.lbpa : FIRM_APPLICATION_TYPES.rrp,        
        approverEmail: null,
        approvalDate: null,
        applicationViableDate: this.data?.firmData?.firmCertificateForRenewal?.expirationDate ? new Date(new Date(this.data.firmData.firmCertificateForRenewal.expirationDate).setDate(new Date(this.data.firmData.firmCertificateForRenewal.expirationDate).getDate() + 1)).toISOString() : null,
    
        firmName: this.data?.firmData?.firm?.name ?? '',
        ccb: this.data?.firmData?.firm?.ccb ?? '',
        renewal: this.data?.firmData?.renewal ?? false,
        previousApplication: this.data?.firmData?.firmCertificateForRenewal?.controlNumber ?? '',
        isPublic: this.data?.firmData?.firm?.isPrivate ?? false,
        inspections: this.data?.firmData?.firmCurrentCredentials?.some(x=>x.firmLeadCredentialType === FIRM_SERVICES.inspections) ?? false,
        clearanceTesting: this.data?.firmData?.firmCurrentCredentials?.some(x=>x.firmLeadCredentialType === FIRM_SERVICES.clearanceTesting) ?? false,
        leadHazardScreening: this.data?.firmData?.firmCurrentCredentials?.some(x=>x.firmLeadCredentialType === FIRM_SERVICES.leadHazardScreening) ?? false,
        riskAssessments: this.data?.firmData?.firmCurrentCredentials?.some(x=>x.firmLeadCredentialType === FIRM_SERVICES.riskAssessments) ?? false,
        abatement: this.data?.firmData?.firmCurrentCredentials?.some(x=>x.firmLeadCredentialType === FIRM_SERVICES.abatement) ?? false,  
        mailingIsPhysical: false,
        contactTitle: this.data?.firmData?.firmContactPerson?.title ?? '',
        contactFirstName: this.data?.firmData?.firmContactPerson?.firstName ?? '',
        contactMiddleName: this.data?.firmData?.firmContactPerson?.middleName ?? '',
        contactLastName: this.data?.firmData?.firmContactPerson?.lastName ?? '',
        contactPhone: this.data?.firmData?.firmContactPersonContactInformation?.phone ?? '',
        contactAlternatePhone: this.data?.firmData?.firmContactPersonContactInformation?.cell ??'',
        contactFaxPhone: this.data?.firmData?.firmContactPersonContactInformation?.fax ?? '',
        contactEmail: this.data?.firmData?.firmContactPersonContactInformation?.email ?? '',
        contactConfirmEmail: this.data?.firmData?.firmContactPersonContactInformation?.email ?? '',
        
        firmPhysicalStreet: this.data?.firmData?.physicalAddress?.street ?? '',
        firmPhysicalState: this.data?.firmData?.physicalAddress?.state ??'',
        firmPhysicalCity: this.data?.firmData?.physicalAddress?.city ?? '',
        firmPhysicalZip: this.data?.firmData?.physicalAddress?.zip ?? '',
        firmPhysicalCounty: this.data?.firmData?.physicalAddress?.county ??'',
        firmMailingStreet: this.data?.firmData?.mailingAddress?.street ?? '' ,
        firmMailingState: this.data?.firmData?.mailingAddress?.state ?? '',
        firmMailingCity: this.data?.firmData?.mailingAddress?.city ??'',
        firmMailingZip: this.data?.firmData?.mailingAddress?.zip ??'',
        firmMailingCounty: this.data?.firmData?.mailingAddress?.county ?? '',
        firmPhone: this.data?.firmData?.contactInformation?.phone ?? '',
        firmAlternatePhone: this.data?.firmData?.contactInformation?.cell ?? '',
        firmFaxPhone: this.data?.firmData?.contactInformation?.fax ?? '',
        firmEmail: this.data?.firmData?.contactInformation?.email ??'',
        firmConfirmEmail: this.data?.firmData?.contactInformation?.email ??'',
        firmWebsite: this.data?.firmData?.contactInformation?.website ?? '',
        firmServices: this.data?.firmData?.firm?.servicesAvailable ?? FIRM_SERVICE_FOR.unk,
    
        attestation: false,
        esignature: '',
      }

      this.updateIndividualTable();
      this.loadFormFields();
      
      this.data.application = this.firmApplication;      
      this.loading = false;      
    }
  }

  get displayedDeficiencyColumnNames() {
    return this.displayedDeficiencyColumnsFormat.map(column => column.columnName);
  }
  
  getStatusTypeValues() {        
    let typeValues = (this.firmApplication.status === APPLICATION_STATUS.approved) ? Object.values(APPLICATION_STATUS) : Object.values(APPLICATION_STATUS).filter(x=>x!==APPLICATION_STATUS.approved);
    return typeValues;
  }

  getFirmServiceForTypeValues() {    
    return Object.values(FIRM_SERVICE_FOR);
  }

  editDeficiency(deficiencyToEdit: IFirmApplicationDeficiency){
    let dialogRef = this.dialog.open(AddFirmApplicationDeficiencyComponent, {
      width: this.isMobile ? '90%' : '40%',
      data: {deficiency: deficiencyToEdit, firmApplication: this.firmApplication}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.updateFirmApplicationDeficiencies();
      }        
    });
  }

  toggleDeficiencyResolved(deficiencyToEdit: IFirmApplicationDeficiency, isChecked: boolean){
    deficiencyToEdit.isResolved = isChecked;
    this.firmService.saveFirmApplicationDeficiency(deficiencyToEdit).subscribe(result=>{},error=>{console.error("There was an error saving the deficiency:", error)});
  }

  deleteDeficiency(deficiency: IFirmApplicationDeficiency) {    
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: {message:'Are you sure you want to remove the application deficiency with ID: <strong>' + deficiency.id + '</strong>?',title:'Delete Application Deficiency'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.firmService.deleteFirmApplicationDeficiency(deficiency.id).subscribe(result=>{
          this.updateFirmApplicationDeficiencies();
        },error=>{this.toastr.error("There was an error deleting the application deficiency: ", error)})
      }
    })
  }  

  updateFirmApplicationDeficiencies(){
    this.firmService.getFirmApplicationDeficiencies(this.data.application.id).subscribe(result=>{      
      this.applicationDeficiencies = result.sort((a, b) => {
        // Check if both items have the same IsResolved status
        if (a.isResolved === b.isResolved) {
          // If so, sort by id in descending order
          return b.id - a.id;
        } else {
          // If not, ensure items with IsResolved as false come first
          return a.isResolved ? 1 : -1;
        }
      });
      this.filterDeficiencyTable();
    },error=>{
      this.toastr.error("There was an error getting the application deficiencies: ", error);
    });
  }

  updateIndividualTable(){    
    this.individualTable = 
    {
      icon: 'staff',
      title:'Certified Individuals',
      layout:{
        columns:['personId', 'firstName','lastName'],
        container:[
          {displayName:'Id',columnName:'personId', type:'string', size: '10'},
          {displayName:'First Name',columnName:'firstName', type:'string', size: '40'},          
          {displayName:'Last Name',columnName:'lastName', type:'string', size: '43'},          
        ],
        data: []
      }
    };
  }

  assignApplicationPersonsToIData() {    
    const iData = [];
    this.applicationPersons.forEach((applicationPerson) => {      
      iData.push({
        id: applicationPerson.id,
        IDNumber: applicationPerson.certificateId,
        Name: applicationPerson.firstName + ' ' + applicationPerson.lastName,
        ActiveDisciplines: applicationPerson.discipline,
        Atp: applicationPerson.atp,
        TrainingDate: applicationPerson.trainingDate,
        FirmIsPaying: applicationPerson.firmIsPaying,
        ['Documents']: applicationPerson['Documents'] 
      })
    });
    return iData;
  }

  assignApplicationPerson() {    
    const iData = [];
    this.firmApplicationPerson.forEach((applicationPerson) => {      
      iData.push({
        Id: applicationPerson.person.id,        
        Name: applicationPerson.person.firstName + ' ' + applicationPerson.person.lastName,            
      })
    });    
    return iData;
  }

  onCoverFee(row: any){             
    let person = this.applicationPersons.filter(x=>x.id === row.id)[0]
    person.firmIsPaying = !row.FirmIsPaying;    
    person.applicationId = person.application.id;    
    this.firmService.saveFirmApplicationPerson(person).subscribe(result=>{},
      error=>{console.log("An error occurred while saving the individual:", error)});
  }

  saveApplication(): void {    
    this.firmApplication.userID = this.firmApplication.userID??this.userID;
    this.updateFirmApplicationData();  
    this.reload = this.firmApplication.id === 0; 
    this.firmService.saveFirmApplication(this.firmApplication).subscribe(
      result => {
        this.toastr.success("Application Saved");
        this.changeIndicator++;
        if(this.reload){
          this.firmApplication.id = result.id;
          this.updateFirmApplicationDeficiencies();          
        }          
      },
      error => {
        this.toastr.error("Ther was an error saving the application: ",error);
      }
    )
  }

  exitApplication(){
    this.dialogRef.close(this.reload);    
  }
  
  setApplicationDataFormFields(){       
    this.applicationDataForm = new UntypedFormGroup({
      approvalDate: new UntypedFormControl(""),
      applicationDate: new UntypedFormControl("", [Validators.required]),
      applicationViableDate: new UntypedFormControl("", [Validators.required]),      
      firmName: new UntypedFormControl("", [Validators.required]),
      status: new UntypedFormControl(""),      
      applicationType: new UntypedFormControl(""),
      ccb: new UntypedFormControl(""),
      renewal: new UntypedFormControl({value: false, disabled: true}),
      previousApplication: new UntypedFormControl({value: "", disabled: this.data?.firmData?.renewal || this.data?.application?.renewal}),        
      isPublic: new UntypedFormControl(false), 
      inspections: new UntypedFormControl(false), 
      clearanceTesting: new UntypedFormControl(false), 
      leadHazardScreening: new UntypedFormControl(false), 
      riskAssessments: new UntypedFormControl(false), 
      abatement: new UntypedFormControl(false),
      mailingIsPhysical: new UntypedFormControl(false),
      contactTitle: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }),
      contactFirstName: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }, !this.isApplicationForExistingFirm ? [Validators.required] : []),
      contactMiddleName: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }),
      contactLastName: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }, !this.isApplicationForExistingFirm ? [Validators.required] : []),  
      contactPhone: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }, !this.isApplicationForExistingFirm ? [Validators.required, Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)] : []),
      contactAlternatePhone: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }, !this.isApplicationForExistingFirm ? [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)] : []),
      contactFaxPhone: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }, !this.isApplicationForExistingFirm ? [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)] : []),
      contactEmail: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }, !this.isApplicationForExistingFirm ? [Validators.required, Validators.email] : []),
      contactConfirmEmail: new UntypedFormControl({value: "", disabled: this.isApplicationForExistingFirm }, !this.isApplicationForExistingFirm ? [Validators.required, Validators.email] : []), 
      firmPhysicalState: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required] : []),
      firmPhysicalStreet: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required] : []),
      firmPhysicalCity: new UntypedFormControl("",!this.isApplicationForExistingFirm ? [Validators.required] : []),
      firmPhysicalZip: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)] : []),
      firmPhysicalCounty: new UntypedFormControl(""),        
      firmMailingStreet: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required] : []),
      firmMailingCity: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required] : []),
      firmMailingState: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required] : []),
      firmMailingZip: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required,Validators.pattern(/^\d{5}(-\d{4})?$/)] : []),
      firmMailingCounty: new UntypedFormControl(""),
      firmPhone: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required, Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)] : []),
      firmAlternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      firmFaxPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      firmEmail: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required, Validators.email] : []),
      firmConfirmEmail: new UntypedFormControl("", !this.isApplicationForExistingFirm ? [Validators.required, Validators.email] : []),    
      firmWebsite: new UntypedFormControl(""),
      firmServices: new UntypedFormControl(""),
    }, {
      validators: [
        this.commonService.emailValidator('contactEmail', 'contactConfirmEmail'),
        this.commonService.emailValidator('firmEmail', 'firmConfirmEmail'), 
      ]
    }); 

    this.applicationAttestationForm = new UntypedFormGroup({
      attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
      esignature: new UntypedFormControl("", [Validators.required])
    });    
  }

  requiredFieldValidator(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fieldValue = control.value[fieldName];
      const sameFieldValue = control.value.mailingIsPhysical;
      if (!sameFieldValue) {
        return (fieldValue !== null && fieldValue !== '' && fieldValue !== undefined) ? null : { [`${fieldName}Required`]: true };
      }
      return null;
    };
  }

  updateApplicationDeficiencyDebounceString(event: any){    
    this.applicationDeficiencyDebounceString.next(event.target.value);    
  }
  filterDeficiencyTable(){    
    this.filteredApplicationDeficiencies = this.applicationDeficiencies.filter(x=>
      (x.id + ' ' + x.description).toLowerCase().includes(this.applicationDeficiencyFilterString)
      && ((this.applicationDeficiencyStatusFilterString.value === "both") ? true : (x.isResolved.toString() === this.applicationDeficiencyStatusFilterString.value)) 
    );        
    this.updateDeficiencySource();  
  }

  updateDeficiencySource(){
    this.deficiencyDataSource = new MatTableDataSource<any>(this.filteredApplicationDeficiencies);
    this.deficiencyDataSource.sort = this.deficiencySort;
    this.deficiencyDataSource.paginator = this.deficiencyPaginator;
  }

  updateApplicationPersonDebounceString(event: any){        
    this.applicationPersonDebounceString.next(event.target.value);    
  }
  filterPersonTable(){        
    this.filteredApplicationPerson = this.firmApplicationPerson.filter(x => {      
      return x.person?.id?.toString().includes(this.applicationPersonFilterString) ||      
        x.person?.firstName?.toString().toLowerCase().includes(this.applicationPersonFilterString.toLowerCase()) ||
        x.person?.lastName?.toString().toLowerCase().includes(this.applicationPersonFilterString.toLowerCase())
      }).map(x=>{
      const {id: personId, ...person} = x.person;      
      return {...x, ...person, personId};
    });   
    this.updatePersonSource();  
  }

  updatePersonSource(){
    this.personDataSource = new MatTableDataSource<any>(this.filteredApplicationPerson);
    this.personDataSource.sort = this.personSort;
    this.personDataSource.paginator = this.personPaginator;
  }

  updateFirmApplicationData(): void {    
    for (const controlName in this.firmApplication) {
      if (this.applicationDataForm.controls.hasOwnProperty(controlName)) {
        this.firmApplication[controlName] = this.applicationDataForm.controls[controlName].value;        
      } else if (this.applicationAttestationForm.controls.hasOwnProperty(controlName)) {
        this.firmApplication[controlName] = this.applicationAttestationForm.controls[controlName].value;
      }
    } 
    if(this.data?.firmData?.firm !== undefined)
    {
      this.firmApplication.firm = this.data.firmData.firm;
    }
  }

  onApplicationTypeChange(event: any) {        
    this.updateFirmApplicationData();
    this.updateIndividualTable();    
  }

  loadFormFields(){
    for (const controlName in this.firmApplication) {
      if (this.applicationDataForm.controls.hasOwnProperty(controlName)) {        
        this.applicationDataForm.controls[controlName].setValue(this.firmApplication[controlName]);  
        if(!this.canEdit)
          this.applicationDataForm.controls[controlName].disable();
      } else if (this.applicationAttestationForm.controls.hasOwnProperty(controlName)) {
        this.applicationAttestationForm.controls[controlName].setValue(this.firmApplication[controlName]);
        if(!this.canEdit)
          this.applicationAttestationForm.controls[controlName].disable();
      }
    }

    this.mailingIsSame();    
  }
  
  deleteIndividual(row: any){             
    let name = row.person.firstName + " " + row.person.lastName;

    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: {message:'Are you sure you want to remove the staff member: <strong>' + name + '</strong>?',title:'Delete Application Staff Member'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.firmService.deleteFirmPerson(row.id).subscribe(res=>{      
          this.updateFirmApplicationPeople();
        },err=>{this.toastr.error("An error occurred when deleting the application staff member: ", err)});
      }
    })
  }

  editIndividual(event: MouseEvent, individual: IFirmPerson) {         
    const dialogRef = this.dialog.open(AddFirmApplicationPersonComponent, {        
      data: {firmApplication: this.firmApplication, person: individual, firmPeople: this.firmApplicationPerson},
      width: this.isMobile ? '90%' : '30%',
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){    
        this.updateFirmApplicationPeople();            
      }    
    });    
  }

  updateFirmApplicationPeople():void {
    this.firmService.getFirmApplicationPeople(this.data.application.id).subscribe(res=>{      
      this.firmApplicationPerson = res.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));          
      this.filterPersonTable();
    },err=>{this.toastr.error("An error occurred getting the Firm Application People: ", err)});
  }

  mailingIsSame(): void {    
    if(!this.isApplicationForExistingFirm){
      if(this.applicationDataForm.get('mailingIsPhysical').value){
        this.applicationDataForm.get('firmMailingStreet').clearValidators();
        this.applicationDataForm.get('firmMailingCity').clearValidators();
        this.applicationDataForm.get('firmMailingState').clearValidators();
        this.applicationDataForm.get('firmMailingZip').clearValidators();
      }
      else{
        this.applicationDataForm.get('firmMailingStreet').setValidators([Validators.required]);
        this.applicationDataForm.get('firmMailingCity').setValidators([Validators.required]);
        this.applicationDataForm.get('firmMailingState').setValidators([Validators.required]);
        this.applicationDataForm.get('firmMailingZip').setValidators([Validators.required]);
      }
      this.applicationDataForm.get('firmMailingStreet').updateValueAndValidity();
      this.applicationDataForm.get('firmMailingCity').updateValueAndValidity();
      this.applicationDataForm.get('firmMailingState').updateValueAndValidity();
      this.applicationDataForm.get('firmMailingZip').updateValueAndValidity();
    }
  }

  approveFirm(){
    const dialogRef = this.dialog.open(ConfirmationDialogueWithFieldComponent, {
      width: '400px',
      autoFocus: false,
      data: {message:'Are you sure you want to <strong>approve</strong> this application?',title:'Approve Application',type:'firm', controlNumber: this.firmApplication.previousApplication, selectedDate: this.firmApplication.renewal ? this.firmApplication.applicationViableDate : new Date().toISOString()},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result !== false && result !== undefined && result !== null){ 
        this.applicationDataForm.controls.previousApplication.setValue(result.controlNumber);
        this.issueDate = result.issueDate;

        this.updateFirmApplicationData();            
        
        if(this.firmApplication.approvalDate === null || this.firmApplication.approvalDate === undefined || this.firmApplication.approvalDate === "" || isNaN(new Date(this.firmApplication.approvalDate).getTime())){
          this.firmApplication.approvalDate = new Date().toISOString();
        }

        this.firmService.getFirms().subscribe(res=>{
          let certNumber = (res.length + 1).toString();          
          const firm: IFirm = {
            id: this.data?.firmData?.firm?.id ?? 0,
            inactive: this.data?.firmData?.firm?.inactive ?? false,
            isPrivate: this.firmApplication.isPublic,
            name: this.firmApplication.firmName,
            servicesAvailable: this.firmApplication.firmServices,
            ccbExpirationDate: null,
            ccb: this.firmApplication.ccb,
            certificationNumber: certNumber,
          };
          
          this.firmService.saveFirm(firm).subscribe(
            res=>{
              this.toastr.success("Firm Application Approved");              
              this.saveFirmApprovalData(res);
            },
            err=>{this.toastr.error("An error occurred saving the firm: ", err)},
          )
        },err=>{});
      }
    })
  }

  saveFirmApprovalData(savedFirm: IFirm){
    const currentDate = new Date(this.issueDate);
    const expirationDate = new Date(currentDate);
    expirationDate.setFullYear(currentDate.getFullYear() + 3);
    
    this.data.application.approverEmail = this.userID;    
    this.data.application.status = APPLICATION_STATUS.approved;
    this.data.application.firm = savedFirm;
    this.data.application.applicationViableDate = this.firmApplication.applicationViableDate;
    
    const firmCertificate: IFirmCertificate = {
      id: 0,
      codeType: (this.firmApplication.applicationType === 'rrp') ? CODE_TYPES.rrpFirm : CODE_TYPES.lbpFirm,
      controlNumber: this.data?.firmData?.firmCertificateForRenewal?.controlNumber ?? this.applicationDataForm.controls.previousApplication.value,
      stage: this.data.application.renewal ? CERTIFICATE_STAGE_TYPES.recert : CERTIFICATE_STAGE_TYPES.initial,
      issueDate: currentDate.toISOString(),
      expirationDate: expirationDate.toISOString(),
      firm: savedFirm,
      application: this.firmApplication,
      dateOfLetter: null,
    };
    const newAddressPhysical: IAddress = {
      id: this.data?.firmData?.physicalAddress?.id ?? 0,
      city: this.firmApplication.firmPhysicalCity,
      contactType: 'Physical',            
      county: this.firmApplication.firmPhysicalCounty,
      state: this.firmApplication.firmPhysicalState,
      street: this.firmApplication.firmPhysicalStreet,
      zip: this.firmApplication.firmPhysicalZip,
    };
    const firmAddressPhysical: IFirmAddress = {
      id: 0,
      firm: savedFirm,
      address: newAddressPhysical,
      isCurrentPhysical: true,      
      isCurrentMailing: false,      
    };
    const newAddressMailing: IAddress = {
      id: this.data?.firmData?.mailingAddress?.id ?? 0,
      city: (this.firmApplication.mailingIsPhysical ? this.firmApplication.firmPhysicalCity : this.firmApplication.firmMailingCity),
      contactType: 'Mailing',            
      county: this.firmApplication.mailingIsPhysical ? this.firmApplication.firmPhysicalCounty : this.firmApplication.firmMailingCounty,
      state: this.firmApplication.mailingIsPhysical ? this.firmApplication.firmPhysicalState : this.firmApplication.firmMailingState,
      street: this.firmApplication.mailingIsPhysical ? this.firmApplication.firmPhysicalStreet : this.firmApplication.firmMailingStreet,
      zip: this.firmApplication.mailingIsPhysical ? this.firmApplication.firmPhysicalZip : this.firmApplication.firmMailingZip,
    };
    const firmAddressMailing: IFirmAddress = {
      id: 0,
      firm: savedFirm,
      address: newAddressMailing,
      isCurrentPhysical: false,      
      isCurrentMailing: true,      
    };
    const accountAffiliation: IAccountAffiliation = {
      id: 0,
      userID: this.data.application.userID,
      firm: savedFirm,
      atp: null,
      status: 'Active',
      person: null,
    };
    const contactInformationForFirm: IContactInformation = {
      id: this.data?.firmData?.contactInformation?.id ?? 0,
      contactType: 'Work',
      phone: this.firmApplication.firmPhone,
      cell: this.firmApplication.firmAlternatePhone,
      fax: this.firmApplication.firmFaxPhone,
      ext: '',
      email: this.firmApplication.firmConfirmEmail,
      website: this.firmApplication.firmWebsite,
      label: ''
    };
    const firmContactInformation: IFirmContactInformation = {
      id: 0,
      firm: savedFirm,
      contactInformation: contactInformationForFirm,
    };
    const personForFirmAndContact: IPerson = {
      id: 0,
      title: this.firmApplication.contactTitle,
      firstName: this.firmApplication.contactFirstName,
      middleName: this.firmApplication.contactMiddleName,
      lastName: this.firmApplication.contactLastName,
      ssn: '',
      dateOfBirth: null,
      response: '',
      inactive: false,
      certificates: null
    }
    const contactInformationForPerson: IContactInformation = {
      id: 0,
      contactType: 'Work',
      phone: this.firmApplication.contactPhone,
      cell: this.firmApplication.contactAlternatePhone,
      fax: this.firmApplication.contactFaxPhone,
      ext: '',
      email: this.firmApplication.contactConfirmEmail,
      website: '',
      label: ''
    };
    const personContactInformation: IPersonContactInformation = {
      id: 0,
      person: personForFirmAndContact,
      contactInformation: contactInformationForPerson,
    };
    const firmPerson: IFirmPerson = {
      id: 0,
      firm: savedFirm,
      person: personForFirmAndContact,
      application: null,      
      isPOC: true,
    };

    let credentialsToAdd:IFirmLeadCredential[] = []              
    let credentialsToDelete:IFirmLeadCredential[] = []
    for(const key in FIRM_SERVICES) {       
      if(this.firmApplication[key]){ 
        if (!this.data?.firmData?.firmCurrentCredentials?.some(x=>x.firmLeadCredentialType === FIRM_SERVICES[key]) ?? false) {                    
          credentialsToAdd.push({
            id: 0,
            firm: savedFirm,
            firmLeadCredentialType: FIRM_SERVICES[key],
          });
        }
      }
      else {
        if(this.data?.firmData?.firmCurrentCredentials?.some(x=>x.firmLeadCredentialType === FIRM_SERVICES[key]) ?? true){
          credentialsToDelete.push(this.data?.firmData?.firmCurrentCredentials?.filter(x=>x.firmLeadCredentialType === FIRM_SERVICES[key])[0]);
        }
      }            
    }    

    if(this.isApplicationForExistingFirm)
    {
      this.commonService.saveContactInformation(contactInformationForFirm).subscribe(result=>{},error=>{
        this.toastr.error("An error occurred saving the firm contact information: ", error)
      });

      this.commonService.saveAddress(newAddressPhysical).subscribe(res=>{}, err=>{
        this.toastr.error("An error occurred saving the physical address: ", err)
      });

      this.commonService.saveAddress(newAddressMailing).subscribe(res=>{}, err=>{
        this.toastr.error("An error occurred saving the mailing address: ", err)
      });
    }
    else
    {
      this.firmApplicationPerson.forEach(firmPerson=>{
        firmPerson.firm = savedFirm;
        this.firmService.saveFirmPerson(firmPerson).subscribe(res=>{
          firmPerson = res;
        },err=>{this.toastr.error("An error occurred saving the firm person: ", err)});
      })
  
      this.personService.savePerson(personForFirmAndContact).subscribe(personResult=>{
        firmPerson.person = personResult;
        this.firmService.saveFirmPerson(firmPerson).subscribe(
          res=>{
            personContactInformation.person = res.person;
            this.personService.savePersonContactInfo(personContactInformation).subscribe(
              res=>{},
              err=>{this.toastr.error("There was an error saving the person contact information: ", err)}
            )
          },
          err=>{this.toastr.error("There was an error saving the firm person: ", err)}
        )          
      },err=>{})
  
      this.firmService.saveFirmContactInformation(firmContactInformation).subscribe(
        res=>{},
        err=>{this.toastr.error("There was an error saving the firm contact information: ", err)}
      )
  
      this.commonService.saveAccountAffiliation(accountAffiliation).subscribe(
        res=>{},
        err=>{this.toastr.error("There was an error saving the account affiliation: ", err)}
      )                

      this.commonService.saveAddress(newAddressPhysical).subscribe(
        res=>{            
          firmAddressPhysical.address = res;
          this.firmService.saveFirmAddress(firmAddressPhysical).subscribe(   
            res=>{},         
            err=>{this.toastr.error("There was an error saving the firm address: ", err)}
          );
        },
        err=>{this.toastr.error("An error occurred saving the physical address: ", err)}
      )   
  
      this.commonService.saveAddress(newAddressMailing).subscribe(
        res=>{            
          firmAddressMailing.address = res;
          this.firmService.saveFirmAddress(firmAddressMailing).subscribe(
            res=>{},
            err=>{this.toastr.error("There was an error saving the firm address: ", err)}
          );
        },
        err=>{this.toastr.error("An error occurred saving the mailing address: ", err)}
      )
    }

    this.firmService.saveFirmApplication(this.data.application).subscribe(
      res=>{},
      err=>{this.toastr.error("There was an error saving the firm license: ", err)}
    );

    this.firmService.saveFirmCertificate(firmCertificate).subscribe(
      res=>{this.dialogRef.close(true);},
      err=>{this.toastr.error("There was an error saving the firm certificate: ", err)}
    );

    credentialsToAdd?.forEach(credentialToAdd=>{
      this.firmService.saveFirmLeadCredential(credentialToAdd).subscribe(res=>{},err=>{
        this.toastr.error("There was an error saving the firm credential: ", err)
      })
    })

    credentialsToDelete?.forEach(credentialToDelete=>{
      if(credentialToDelete !== undefined){
        this.firmService.deleteFirmLeadCredential(credentialToDelete.id).subscribe(res=>{},err=>{
          this.toastr.error("There was an error deleting the firm credential: ", err)
        })
      }
    })
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  refreshTableApplicationDocuments(){
    this.firmService.getFirmApplicationDocuments(this.firmApplication.id).subscribe(result=>{
      this.tableApplicationDocumentsData = result.sort((a, b) => a.fileName.localeCompare(b.fileName));
      this.filterDocumentTable();
    },error=>{
      this.toastr.error("There was an error getting the documents: ", error);
    })
  }

  editDocument(row: any){
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      width: this.isMobile ? '90%' : '30%',
      data: {document: row, entity: 'firm', entityRef: this.firmApplication},
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
        this.refreshTableApplicationDocuments();      
    });
  }    

  async downloadFile(row: any) {    
    this.commonService.downloadFileStream(row);
  }  

  clearFile(row: any) {      
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> this Application Document?',title: 'Delete the Application Dcoument'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.firmService.deleteFirmApplicationDocument(row.id).subscribe(result=>{
          this.commonService.deleteDocumentFile(row.document.id).subscribe(result=>{
            this.refreshTableApplicationDocuments();
          }, error=>{
            this.toastr.error("An error occurred while deleting the document: ", error);
          });
        },
        error=>{
          this.toastr.error("An error occurred while deleting the document: ", error);
        });                 
      }
    })
  }

  filterDocumentTable(): void {
    this.tableApplicationDocumentsFilteredData = this.tableApplicationDocumentsData;
    
    this.tableApplicationDocumentsFilteredData = this.tableApplicationDocumentsFilteredData.filter(x => {      
      return x.id?.toString().includes(this.filterDocumentString) ||      
        x.fileName?.toString().toLowerCase().includes(this.filterDocumentString.toLowerCase()) ||
        x.fileDescription?.toString().toLowerCase().includes(this.filterDocumentString.toLowerCase())        
    });

    this.updateDocumentDataSource();
  }
  updateDocumentDataSource(){    
    this.documentDataSource = new MatTableDataSource<any>(this.tableApplicationDocumentsFilteredData);
    this.documentDataSource.paginator = this.documentPaginator;
    this.documentDataSource.sort = this.documentSort;    
  }
  
  paymentUpdated(payments: IPayment[]) {
   // console.log('payments update');
  }
}
