import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { COURSE_DISCIPLINES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { IAtpStudentFilter } from 'app/models/filters/atpStudentFilter';
import { AddEditAtpCourseComponent } from '../add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { AddEditCourseStudentComponent } from '../add-edit-atp/add-edit-course-student/add-edit-course-student.component';
import { AddEditAtpComponent } from '../add-edit-atp/add-edit-atp.component';
import { AddEditIndividualComponent } from 'app/components/individual/add-edit-individual/add-edit-individual.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IPerson } from 'app/models/People/person';
import { MergeIndividualComponent } from 'app/components/individual/merge-individual/merge-individual.component';

@Component({
  selector: 'app-atp-student',
  templateUrl: './atp-student.component.html',
  styleUrls: ['./atp-student.component.scss']
})
export class AtpStudentComponent {
  public isMobile: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public loading: boolean = true;
  public pageSize: number = 50;  
  public students: any[] = [];
  public dataSource: MatTableDataSource<any>;
  public subTableDataSource: MatTableDataSource<any>;
  public filteredStudents: any[] = [];  
  public subTable: any[] = [];  
  public atpList: any[] = [];
  public filteredAtpList: any[] = [];  
  public selectedStudents: IPerson[] = [];

  public filterString: string = '';
  public filterATPString: any = '';
  public filterCourseString: string = '';
  public selectedDisciplines = new FormControl([]);    
  public startDate = new Date(new Date().setMonth(new Date().getMonth() - 6));

  public endDate = new Date();
  public studentFilter: IAtpStudentFilter = {
    filterString: '', 
    filterATPString: '', 
    filterCourseString: '',
    selectedDisciplines: '', 
    startDate: this.startDate.toISOString(),
    endDate: this.endDate.toISOString()
  };

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  @ViewChild('NestedTableSort') subsort: MatSort;
  @ViewChild('NestedTablePaginator') subpaginator: MatPaginator;
  
  studentDataFields = {    
    layout:{
      columns:['select', 'id', 'firstName', 'lastName', 'atpCourses'],
      container:[
        {displayName:'',columnName:'select', type:'select', size:'5'},
        {displayName:'Person Id',columnName:'id', type:'string', size:'10'},        
        {displayName:'First Name',columnName:'firstName', type:'string', size:'10'},        
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'15'}, 
        {displayName:'Student Records',columnName:'atpCourses', type:'subtable', size:'60'},                
      ],        
    }
  }

  subTableFields = {    
    layout:{      
      columns:['name', 'courseId', 'accreditationDiscipline', 'courseCertificateNumber', 'testScore', 'courseStart','actions'],      
      container:[        
        {displayName:'ATP',columnName:'name', type:'atp', size:'25'},  
        {displayName:'Course Id',columnName:'courseId', type:'course', size:'10'},  
        {displayName:'Discipline',columnName:'accreditationDiscipline', type:'course', size:'13'},  
        {displayName:'Certificate',columnName:'courseCertificateNumber', type:'string', size:'18'},     
        {displayName:'Test Score',columnName:'testScore', type:'string', size:'12'},           
        {displayName:'Course Date',columnName:'courseStart', type:'course', size:'13'},          
      ],        
    }
  }
  private debounceString: Subject<string> = new Subject<string>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonDataService,
    public atpService: AtpService,    
    public toastr: ToastrService) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.initializeDebounceFunction();
    this.loadFilters();
    this.loadIndividuals();
  }

  loadFilters(): void {
    this.filterString = this.atpService.atpStudentFilterString;
    this.filterATPString = this.atpService.atpStudentFilterATPString;
    this.filterCourseString = this.atpService.atpStudentFilterCourseString;  
    this.selectedDisciplines = this.atpService.atpStudentSelectedDisciplines;    
    this.startDate = this.atpService.atpStudentStartDate;
    this.endDate = this.atpService.atpStudentEndDate;

    this.studentFilter.filterString = this.filterString;
    this.studentFilter.filterATPString = this.filterATPString;
    this.studentFilter.filterCourseString = this.filterCourseString;
    this.studentFilter.selectedDisciplines = this.selectedDisciplines.value.join(',');
    this.studentFilter.startDate = this.startDate?.toISOString();
    this.studentFilter.endDate = this.endDate?.toISOString();
  }

  loadIndividuals(): void {  
    this.refreshStudents();
    this.atpService.getATPs().subscribe(result=>{
      this.atpList = result.map(atp=>{return {name: atp.name, id: atp.id}});
      this.atpList.sort((a, b) => a.name.localeCompare(b.name));
      this.filteredAtpList = this.atpList;      
    },error=>{this.toastr.error("Error loading ATPs");})
  }
  
  refreshStudents(){
    this.atpService.getAllStudents(this.studentFilter).subscribe(result=>{            
      this.students = result.map(item => {
        const student = {
          ...item,
          id: item.person.id,
          firstName: item.person.firstName,
          lastName: item.person.lastName,
        };
      
        if (student.atpStudentCourses) {   
          const mappedCourses = student.atpStudentCourses.map(course => {              
            return {
              ...course,
              name: course.atpCourse.atp.name,
              courseId: course.atpCourse.id,
              accreditationDiscipline: course.atpCourse.accreditationDiscipline,
              courseStart: course.atpCourse.courseStart,                            
            };
          });          
          student.courses = new MatTableDataSource(mappedCourses);
          student.courses.paginator = this.subpaginator;
          student.courses.sort = this.subsort;
        }
      
        return student;
      });

      this.filteredStudents = this.students;
      this.updateDataSource();
      this.loading = false;
    },error=>{this.toastr.error("Error loading students");})    
  }
  
  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredStudents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editATP(row: any){
    const dialogRef = this.dialog.open(AddEditAtpComponent, {
      width: '90%',
      data: {atp: row.atpCourse.atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {     
      this.refreshStudents(); 
    });
  }

  editPerson(row: any) {    
    const dialogRef = this.dialog.open(AddEditIndividualComponent, {
      width: '90%',
      data: {person: row},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
    });
  }
  
  editStudent(row: any, nestedRow: any){    
    nestedRow.student = row.person;
    const dialogRef = this.dialog.open(AddEditCourseStudentComponent, {
      width: '90%',
      autoFocus: false,
      data: {student: nestedRow, course: nestedRow.atpCourse},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {   
      this.refreshStudents();   
    });
  }

  editCourse(row: any){
    const dialogRef = this.dialog.open(AddEditAtpCourseComponent, {
      width: '90%',
      data: {course: row.atpCourse, atp: row.atpCourse.atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      this.refreshStudents();    
    });
  }

  filterTable() {     
    this.saveFilters();
    this.refreshStudents();
  }

  saveFilters(){
    this.atpService.atpStudentFilterString = this.filterString;
    this.atpService.atpStudentFilterATPString = this.filterATPString?.name ?? this.filterATPString;
    this.atpService.atpStudentFilterCourseString = this.filterCourseString;
    this.atpService.atpStudentSelectedDisciplines = this.selectedDisciplines;
    this.atpService.atpStudentStartDate = this.startDate;    
    this.atpService.atpStudentEndDate = this.endDate;

    this.studentFilter.filterString = this.filterString;
    this.studentFilter.filterATPString = this.filterATPString?.name ?? this.filterATPString;
    this.studentFilter.filterCourseString = this.filterCourseString;
    this.studentFilter.selectedDisciplines = this.selectedDisciplines.value.join(',');
    this.studentFilter.startDate = this.startDate?.toISOString();
    this.studentFilter.endDate = this.endDate?.toISOString();
  }

  clearFilters(){
    this.filterString = '';
    this.filterATPString = '';
    this.filterCourseString = '';
    this.selectedDisciplines.setValue([]);
    this.startDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
    this.endDate = new Date();
    
    this.selectedStudents = [];
    this.filterTable();
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  getStudentDisciplineValues() {
    return Object.values(COURSE_DISCIPLINES);
  }

  initializeDebounceFunction(): void {
    this.debounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {           
      this.filterATP(value.trim().toLowerCase());     
    });
  }

  filterATP(value: any) {
    if (value.length < 1) {
      this.resetATPList();      
    }
    else{      
      this.filteredAtpList = this.atpList.filter(atp => atp.name.toLowerCase().includes(value) || atp.id.toString().includes(value));
    }
  }

  resetATPList(): void {    
    this.filteredAtpList = this.atpList
  }

  displayATP(atp: any): string {
    if (atp) {
        if (atp.id === 0) {
            return atp?.name ?? atp;
        } else {
            return `${atp?.id ? atp.id + ': ' + atp.name : atp}`;
        }
    } else {
        return '';
    }
  }

  updateDebounceString(event: any){        
    this.debounceString.next(event.target.value);     
  }

  updateATPFilter(): void{
    this.studentFilter.filterATPString = this.filterATPString?.name ?? this.filterATPString;
  }

  mergeStudents(){
    const dialogRef = this.dialog.open(MergeIndividualComponent, {
      width: '90%',
      autoFocus: false,
      data: this.selectedStudents,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      if(result){
        this.selectedStudents = [];
        this.filterTable();
      }
    });
  }

  getMergeTooltip(): string {
    if (this.selectedStudents.length > 0) {
        return 'Merge: ' + this.selectedStudents.map(individual => `${individual.id}: ${individual.firstName} ${individual.lastName}`).join(', ');
    }
    return 'Merges selected individuals';
  }

  updateSelected(studentRecord: any){    
    const index = this.selectedStudents.findIndex(individual => individual.id === studentRecord.person?.id);
    if (index !== -1){
      this.selectedStudents.splice(index, 1);
    } else {            
      var person = studentRecord.person;
      person.affiliations = studentRecord.atpStudentCourses.map(x=>'course:'.concat(x.atpCourse?.id.toString()));
      this.selectedStudents.push(person);      
    }
  }    
  
  isSelected(studentRecord: any): boolean {    
    return this.selectedStudents.some(individual => individual.id === studentRecord.person?.id);
  }
}
