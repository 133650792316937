<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
        <h1>Loading</h1>
    </div>
</div>

<div fxLayout="column">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
            <mat-card [style.background-color]="uiData.atp.color" fxFlex="100">
                <h2 class="noMarginHeader">
                    <mat-icon>{{'recent_actors'}}</mat-icon> {{'Student Management'}}
                </h2>
            </mat-card>
        </div>
    </div>

    <mat-card>
        <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxLayoutGap="8px">
                <strong class="noMarginHeader">Results ({{filteredStudents.length}})</strong>                
                <mat-form-field style="width:170px;">
                    <input placeholder="Filter by Id, Name, Cert" [(ngModel)]="filterString" matInput (keydown.enter)="filterTable()"/>                    
                </mat-form-field>  
                <mat-form-field style="width:170px;">
                    <input placeholder="Filter ATP" [(ngModel)]="filterATPString" matInput [matAutocomplete]="auto" (input)="updateDebounceString($event)" (keydown.enter)="filterTable()"/>                                        
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayATP" (optionSelected)="updateATPFilter()">
                        <mat-option *ngFor="let atp of filteredAtpList" [value]="atp">
                            {{ atp.id === 0 ? atp.name : atp.id + ': ' + atp.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field style="width:170px;">
                    <input placeholder="Filter Course Id" [(ngModel)]="filterCourseString" matInput (keydown.enter)="filterTable()"/>                    
                </mat-form-field>  
                <mat-form-field style="width:170px">
                    <mat-select multiple placeholder="Filter by Discipline" [formControl]="selectedDisciplines">
                    <mat-option *ngFor="let type of getStudentDisciplineValues()" [value]="type">
                        {{ type }}
                    </mat-option>
                    </mat-select>
                </mat-form-field> 
                <mat-form-field style="width:170px">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startPicker" placeholder="Start date" [(ngModel)]="startDate" (keydown.enter)="filterTable()">                                        
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>                                        
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="startDate = null">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>                    
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="width:170px">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endPicker" placeholder="End date" [(ngModel)]="endDate" (keydown.enter)="filterTable()">
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="endDate = null">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>   
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="filterTable()">Search</button>
                <button mat-raised-button (click)="clearFilters()">Reset Filters</button>
                <button mat-raised-button color="warn" [disabled]="selectedStudents.length < 2" (click)="mergeStudents()" [matTooltip]="getMergeTooltip()">
                    <mat-icon>call_merge</mat-icon> Merge Individuals {{selectedStudents.length > 0 ? '(' + selectedStudents.length + ')': ''}}
                </button>
            </div>
            <br />

            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of studentDataFields.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : { 'display': 'flex', 'width': '100%', 'flex-grow': '1'}" matTooltip="{{row[cellData.columnName]}}">
                        <ng-container *ngIf="cellData.type === 'subtable'">                            
                            <mat-table [dataSource]="row.courses" matSort #NestedTableSort="matSort" fxFlex="100%">
                                <ng-container *ngFor="let cellData of subTableFields.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                    <mat-cell *matCellDef="let nestedRow" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                        <ng-container *ngIf="cellData.type === 'course'">
                                            <ng-container *ngIf="cellData.columnName === 'courseId'">
                                                <div *ngIf="isMobile" fxLayout="row">  
                                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                    <a class="blue-text" (click)="editCourse(nestedRow); $event.stopPropagation()">{{nestedRow?.[cellData.columnName]}}</a>
                                                </div>
                                                <div *ngIf="!isMobile">  
                                                    <a class="blue-text" (click)="editCourse(nestedRow); $event.stopPropagation()">{{nestedRow?.[cellData.columnName]}}</a>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="cellData.columnName === 'accreditationDiscipline'">
                                                <div *ngIf="isMobile" fxLayout="row">  
                                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                    {{displayLimit(nestedRow?.atpCourse?.[cellData.columnName], cellData.size)}}
                                                </div>
                                                <div *ngIf="!isMobile">  
                                                    {{displayLimit(nestedRow?.atpCourse?.[cellData.columnName], cellData.size)}}
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="cellData.columnName === 'courseStart'">
                                                <div *ngIf="isMobile" fxLayout="row">  
                                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                    <span>{{(nestedRow?.atpCourse?.[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                                </div>
                                                <div *ngIf="!isMobile">  
                                                    {{(nestedRow?.atpCourse?.[cellData.columnName] | date:'MM/dd/yyyy')}}
                                                </div>
                                            </ng-container>
                                        </ng-container>

                                        <ng-container *ngIf="cellData.type === 'atp'">
                                            <div *ngIf="isMobile" fxLayout="row">
                                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                <a class="blue-text" (click)="editATP(nestedRow); $event.stopPropagation()">{{nestedRow?.[cellData.columnName]}}</a>
                                            </div>
                                            <div *ngIf="!isMobile">                                                
                                                <a class="blue-text" (click)="editATP(nestedRow); $event.stopPropagation()">{{nestedRow?.[cellData.columnName]}}</a>                                                
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="cellData.type !== 'course' && cellData.type !== 'atp'">
                                            <div *ngIf="isMobile" fxLayout="row">
                                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                <span>{{ displayLimit(nestedRow[cellData.columnName], cellData.size) }}</span>
                                            </div>
                                            <div *ngIf="!isMobile">
                                                <span>{{ displayLimit(nestedRow[cellData.columnName], cellData.size) }}</span>
                                            </div>
                                        </ng-container>
                                    </mat-cell>
                                </ng-container> 
                                
                                <!-- Button column -->
                                <ng-container matColumnDef="actions">
                                    <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                                    <mat-cell *matCellDef="let nestedRow" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                        <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                            <span><strong>Action: </strong></span>
                                            <button mat-icon-button color="primary" (click)="editStudent(row, nestedRow); $event.stopPropagation()">
                                                <mat-icon>{{'edit'}}</mat-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="!isMobile">
                                            <button mat-icon-button color="primary" (click)="editStudent(row, nestedRow); $event.stopPropagation()">
                                                <mat-icon>{{'edit'}}</mat-icon>
                                            </button>
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                
                                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="subTableFields.layout.columns"></mat-header-row>
                                <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editStudent(row, nestedRow); $event.stopPropagation()" *matRowDef="let nestedRow; index as i; columns: subTableFields.layout.columns"></mat-row>
                            </mat-table>                  
                        </ng-container>

                        <ng-container *ngIf="cellData.type === 'select'">
                            <div *ngIf="isMobile" fxLayout="row">
                              <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="updateSelected(row)" [checked]="isSelected(row)"></mat-checkbox>
                            </div>
                            <div *ngIf="!isMobile">
                              <mat-checkbox (click)="$event.stopPropagation()" (change)="updateSelected(row)" [checked]="isSelected(row)"></mat-checkbox>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="cellData.type !== 'subtable' && cellData.type !== 'select'">
                            <div *ngIf="isMobile" fxLayout="row">
                              <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                              <ng-container *ngIf="cellData.columnName !== 'atpName' && cellData.columnName !== 'courseId'">
                                <span>{{ displayLimit(row.person[cellData.columnName], cellData.size) }}</span>
                              </ng-container>
                            </div>
                            <div *ngIf="!isMobile">                              
                              <ng-container *ngIf="cellData.columnName !== 'atpName' && cellData.columnName !== 'courseId'">
                                {{displayLimit(row.person[cellData.columnName], cellData.size)}}
                              </ng-container>
                            </div>
                        </ng-container>
                    </mat-cell>
                </ng-container>                                  
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="studentDataFields.layout.columns"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editPerson(row.person); $event.stopPropagation()" *matRowDef="let row; index as i; columns: studentDataFields.layout.columns"></mat-row>
            </mat-table>              
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>              
    </mat-card>   
</div>
