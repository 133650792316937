import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { IPayment } from '../../models/payment';
import { PaymentService } from '../../services/payments.service';
import { DialogEditPaymentComponent } from '../edit/edit-payment-dialog';
import { DialogMarkPaidComponent } from '../edit/mark-paid';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-manage-payments',
  templateUrl: './manage-payments.component.html',
  styleUrls: ['./manage-payments.component.scss']
})

export class ManagePaymentComponent implements OnInit, OnChanges {
  @Input() title = "";
  @Input() entityId = "";
  @Input() entityType = "";
  @Input() allowMultiplePayments = false;
  @Input() allowDelete = true;
  @Input() allowAdd = true;
  @Input() editingPayment = false;
  @Input() cardColor: string = '';
  @Input() changeIndicator:number = 0;
  @Output() notifyParent: EventEmitter<IPayment[]> = new EventEmitter<IPayment[]>();

  public updateParent: boolean = false;
  public processing: boolean = false;
  public isMobile: boolean = false;
  public paymentsSource = new MatTableDataSource<IPayment>();
  public payments: IPayment [] = [];
  public paymentColumns: string[] = ['type','status','paymentCleared','amount','paymentMethod', 'orderNumber', 'comment', 'localReferenceId', 'actions'];
  public paymentColumnDefinitions = [
    { def: 'localReferenceId', label: 'Local Reference Id', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'orderNumber', label: 'Order Number', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'type', label: 'Type', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'status', label: 'Status', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'paymentCleared', label: 'Payment Cleared', width: '10%', hide: false, align: 'left', type: 'boolean', sortable: true },
    { def: 'amount', label: 'Amount', width: '10%', hide: false, align: 'right', type: 'number', sortable: true },
    { def: 'paymentMethod', label: 'Payment Method', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'sku', label: 'SKU', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'comment', label: 'Comments', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'actions', label: '', width: '20%', hide: false, align: 'center', type: 'actions', sortable: false }
  ];
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  public pageSize = 10;  
  
  constructor(public sharedService: SharedService,
              public authService: AuthService,
              public paymentService: PaymentService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private breakpointObserver: BreakpointObserver) 
    {
      this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
      });
    }

  ngOnChanges(): void {
    if(this.changeIndicator > 0) {
      this.loadPayments();
    }
  }

  ngOnInit() {
    if(this.title == "") {
      this.title = "Payments";
    }
    this.loadPayments();
  }

  loadPayments(): void {
    this.processing = true;
    this.paymentService.getPaymentsByEntity(this.entityId, this.entityType).subscribe(
      response => {
        this.payments = response;
        this.paymentsSource = new MatTableDataSource<IPayment>(this.payments);
        if(this.updateParent){
          this.notifyParent.emit(this.payments);
          this.updateParent = false;
        }
      },
      error => console.log('error', error),
      () => { this.processing = false; }
    );
  }

  markAsCleared(payment: IPayment) {
    const dialogRef = this.dialog.open(DialogMarkPaidComponent, {
      data: payment,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.updateParent = true;
        this.loadPayments();
      }
    });
  }

  deletePayment(paymentId: number, type: string ) {
    this.sharedService.openConfirm(
      "Are you sure you want to delete this payment? Continue?"
      );
    this.sharedService.confirmed().subscribe(
      confirmed => {
        if(confirmed){
          this.processing = true;
          this.paymentService.deletePayment(paymentId, type).subscribe(
            () => {
              this.updateParent = true;
              this.loadPayments();
              this.processing = false;
              this.toastr.success("Payment Removed");
            } ,
            error => console.log('error', error)
          );
        }
      }
    );
  }

  addNewPayment() {
    let payment: IPayment = {
      id: 0,
      localReferenceId: '',
      paymentGuid: '',
      paid: false,
      status: '',
      type: this.entityType,
      orderNumber: '',
      orderRecordId: '',
      billingName: '',
      billingAddress: '',
      orderTimePlaced: '',
      quantity: 1,
      amount: "0.00",
      comment: '',
      createdBy: '',
      dastUpdatedBy: '',
      dateCreated: '',
      dateLastUpdated: '',
      entityId: this.entityId,
      entityType: this.entityType,
      paymentCleared: '',
      paymentMethod: '',
      entityDescription: ''
    }

    const dialogRef = this.dialog.open(DialogEditPaymentComponent, {
      data: payment,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.updateParent = true;
        this.loadPayments();
      }
    });
  }

  editPayment(payment: IPayment) {
    const dialogRef = this.dialog.open(DialogEditPaymentComponent, {
      data: payment,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.updateParent = true;
        this.loadPayments();
      }
    });
  }
}
