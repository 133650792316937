<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
        <h1>Loading</h1>
    </div>
</div>

<div fxLayout="column">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
            <mat-card [style.background-color]="uiData.atp.color" fxFlex="100">
                <h2 class="noMarginHeader">
                    <mat-icon>{{'recent_actors'}}</mat-icon> {{'ATP Courses'}}
                </h2>
            </mat-card>
        </div>
    </div>

    <mat-card>
        <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxLayoutGap="8px">
                <strong class="noMarginHeader">Results ({{dataSource?.filteredData?.length}})</strong>                
                <mat-form-field style="width:170px;">
                    <input placeholder="Filter" [(ngModel)]="filterString" matInput (keyup)="applySubFilter($event)" (keydown.enter)="search()"/>                    
                </mat-form-field>  
                <mat-form-field style="width:170px;">
                    <input placeholder="Filter ATP" [(ngModel)]="filterATPString" matInput [matAutocomplete]="auto" (input)="updateDebounceString($event)" (keydown.enter)="search()"/>                                        
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayATP" (optionSelected)="updateATPFilter()">
                        <mat-option *ngFor="let atp of filteredAtpList" [value]="atp">
                            {{ atp.id === 0 ? atp.name : atp.id + ': ' + atp.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field style="width:170px">
                    <mat-label>Start Date</mat-label>
                    <input matInput [matDatepicker]="startPicker" placeholder="Start date" [(ngModel)]="startDate" (keydown.enter)="search()">                                        
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>                                        
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="startDate = null">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>                    
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="width:170px">
                    <mat-label>End Date</mat-label>
                    <input matInput [matDatepicker]="endPicker" placeholder="End date" [(ngModel)]="endDate" (keydown.enter)="search()">
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="endDate = null">
                        <mat-icon style="color: red;">close</mat-icon>
                    </button>   
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
                <mat-checkbox [(ngModel)]="delinquents" (change)="filterTable()">QC Delinquents</mat-checkbox>
                <button mat-raised-button color="primary" (click)="search()">Search</button>
                <button mat-raised-button (click)="clearFilters()">Reset Filters</button>
            </div>
            <br />

            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of courseDataFields.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : { 'display': 'flex', 'width': '100%'}" matTooltip="{{row[cellData.columnName]}}">                        
                        <ng-container>
                            <div *ngIf="isMobile" fxLayout="row">
                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                <ng-container *ngIf="cellData.type === 'date'">
                                    {{row[cellData.columnName] | date:'MM/dd/yyyy'}}
                                </ng-container>
                                <ng-container *ngIf="cellData.type === 'dateTime'">
                                    {{displayDateTime(row)}}
                                </ng-container>
                                <ng-container *ngIf="cellData.type === 'string'">
                                    <ng-container *ngIf="cellData.columnName === 'course'">
                                        <a (click)="editCourse(row); $event.stopPropagation()"><span style="white-space: pre-wrap;">{{row[cellData.columnName]}}</span></a>
                                    </ng-container>                                    
                                    <ng-container *ngIf="cellData.columnName === 'atpName'">
                                        <a (click)="editATP(row.atp); $event.stopPropagation()"><span style="white-space: pre-wrap;">{{row[cellData.columnName]}}</span></a>
                                    </ng-container>                              
                                    <ng-container *ngIf="cellData.columnName !== 'course' && cellData.columnName !== 'atpName'">
                                        <span style="white-space: pre-wrap;">{{row[cellData.columnName]}}</span>
                                    </ng-container> 
                                </ng-container>
                            </div>
                            <div *ngIf="!isMobile">                              
                                <ng-container *ngIf="cellData.type === 'date'">
                                    {{row[cellData.columnName] | date:'MM/dd/yyyy'}}
                                </ng-container>
                                <ng-container *ngIf="cellData.type === 'dateTime'">
                                    {{displayDateTime(row)}}
                                </ng-container>
                                <ng-container *ngIf="cellData.type === 'string'">
                                    <ng-container *ngIf="cellData.columnName === 'course'">
                                        <a (click)="editCourse(row); $event.stopPropagation()"><span style="white-space: pre-wrap;">{{row[cellData.columnName]}}</span></a>
                                    </ng-container>                                    
                                    <ng-container *ngIf="cellData.columnName === 'atpName'">
                                        <a (click)="editATP(row.atp); $event.stopPropagation()"><span style="white-space: pre-wrap;">{{row[cellData.columnName]}}</span></a>
                                    </ng-container>                              
                                    <ng-container *ngIf="cellData.columnName !== 'course' && cellData.columnName !== 'atpName'">
                                        <span style="white-space: pre-wrap;">{{row[cellData.columnName]}}</span>
                                    </ng-container>                                    
                                </ng-container>
                            </div>
                        </ng-container>
                    </mat-cell>
                </ng-container>                                  
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="courseDataFields.layout.columns"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editCourse(row); $event.stopPropagation()" *matRowDef="let row; index as i; columns: courseDataFields.layout.columns"></mat-row>
            </mat-table>              
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>              
    </mat-card>   
</div>
