import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { IPayment } from "../../models/payment";
import { PaymentService } from "../../services/payments.service";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'dialog-edit-payment',
  templateUrl: './edit-payment.html',
  providers: [PaymentService, { provide: MAT_DATE_FORMATS, useValue: DateFormats }],
})
export class DialogEditPaymentComponent implements OnInit {
  processing: boolean = false;
  roles: string[] = [];
  today: Date = new Date(Date.now());

  public paymentForm = new UntypedFormGroup({
    feeType: new UntypedFormControl("", [Validators.required]),
    entityType: new UntypedFormControl("", [Validators.required]),
    status: new UntypedFormControl(""),
    paymentMethod: new UntypedFormControl(""),
    paymentCleared: new UntypedFormControl(""),
    amount: new UntypedFormControl("", [Validators.required]),
    OrderNumber: new UntypedFormControl(""),
    comment: new UntypedFormControl("")
  });

  constructor(public paymentService: PaymentService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<DialogEditPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public payment: IPayment) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.paymentForm.patchValue({
      feeType: this.payment.type,
      entityType: this.payment.entityType,
      status: this.payment.status,
      amount: this.payment.amount,
      OrderNumber: this.payment.orderNumber,
      comment: this.payment.comment,
      paymentMethod: this.payment.paymentMethod
    });


    if (this.payment.paymentCleared != null && this.payment.paymentCleared.length > 0) {
      this.paymentForm.get('paymentCleared').patchValue(new Date(this.payment.paymentCleared));
    }

    if (this.payment.type != null && this.payment.type.length > 0 && (this.payment.amount == null || this.payment.amount.length == 0 || this.payment.amount == "0.00")) {
      this.onChangePaymentType();
    }
  }

  onChangePaymentType() {
    this.paymentForm.get('amount').patchValue(this.paymentService.getPaymentAmount(this.paymentForm.get('entityType').value, this.paymentForm.get('feeType').value));
    let status = this.paymentForm.get('status').value;
    if(status == null || status == undefined || status == "" ) {
      this.paymentForm.get('status').patchValue(this.paymentService.paymentPendingStatus);
    }
  }

  savePayment() {
    if (this.paymentForm.valid) {
      this.processing = true;
      let form = this.paymentForm.value;
      this.payment.type = form.feeType;
      this.payment.entityType = form.entityType;
      this.payment.status = form.status;
      this.payment.amount = form.amount;
      this.payment.paymentMethod = form.paymentMethod;
      this.payment.comment = form.comment;
      this.payment.paymentCleared = form.paymentCleared;
      this.payment.orderNumber = form.OrderNumber;

      if (this.payment.entityId == null || this.payment.entityId == undefined || this.payment.entityId.length == 0) {
        this.toastr.error("Invalid Payment Entity Id");
        this.processing = false;
        return;
      }

      if (this.payment.type == null || this.payment.type == undefined || this.payment.type.length == 0) {
        this.toastr.error("Invalid Payment Type");
        this.processing = false;
        return;
      }

      if (this.payment.amount == null || this.payment.amount == undefined || this.payment.amount.length == 0) {
        this.toastr.error("Invalid Payment amount");
        this.processing = false;
        return;
      }

      if (this.payment.status != null && this.payment.status != undefined && this.payment.status == this.paymentService.paymentClearedStatus) {
        this.payment.paid = true
      }
      else {
        this.payment.paid = false;
      }

      this.paymentService.savePayment(this.payment).subscribe(
        response => {
          this.processing = false;
          this.toastr.success("Payment Saved");
          this.dialogRef.close(response);
        },
        error => {
          console.error('error: ' + error.statusText);
          this.processing = false;
          this.toastr.error("Unable to save payment");
        }
      );
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

}
