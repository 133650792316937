import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ToastrService } from 'ngx-toastr';
import { PrintCertificateComponent } from '../print-certificate/print-certificate.component';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-print-certificate-modal',
  templateUrl: './print-certificate-modal.component.html',
  styleUrls: ['./print-certificate-modal.component.scss']
})
export class PrintCertificateModalComponent{

  public uiData = LEAD_BASED_PAINT;
  public selectedCertificates: boolean = false;
  public selectedBadges: boolean = false;
  public selectedLetters: boolean = false;
  
  constructor(private dialogRef: MatDialogRef<PrintCertificateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{ person:any, address:any, firmName:string }, 
    private toastr: ToastrService, public dialog: MatDialog,public sharedService: SharedService) { }  

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onPrintClick(): void {
    this.dialog.open(PrintCertificateComponent, {
        data: {person: this.data.person, address: this.data.address, firmName: this.data.firmName, selectedCertificates: this.selectedCertificates, selectedBadges: this.selectedBadges, selectedLetters: this.selectedLetters},
          role: 'dialog',
          ariaLabel: 'Complaint',
          minWidth: !this.sharedService.mobile ? '1000px' : '300px',
          maxWidth: !this.sharedService.mobile ? '980px' : '300px'
        });
  }
}
