<mat-card [style.background]="cardColor">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
        <span fxFlex style="font-size: 24px; font-weight: 500">{{title}} </span>
        <span *ngIf="editingPayment" class="f-s-14">Click Entry to Edit </span>
        <button *ngIf="(payments.length == 0 || allowMultiplePayments) && allowAdd" type="button" [disabled]="!editingPayment" mat-icon-button color="primary"
                matTooltip="Add New Payment" matTooltipShowDelay=800 aria-label="Add New Payment" (click)="addNewPayment()">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-table [dataSource]="paymentsSource" matSort #TableSort="matSort" aria-describedby="owners" class="mat-elevation-z8" style="overflow-x: auto;">
        <ng-container *ngFor="let cellData of paymentColumnDefinitions; let i = index" [matColumnDef]="cellData.def">
          <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.width" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.label}}</mat-header-cell>
          <mat-cell *matCellDef="let row" [fxFlex]="cellData.width" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.def]}}">
            <ng-container *ngIf="cellData.def !== 'type' && cellData.def !== 'status' && cellData.def !== 'actions'">
              <div *ngIf="isMobile" fxLayout="row">  
                <span><strong>{{cellData.label}}: </strong></span> &nbsp;
                <span>{{row[cellData.def]}}</span>
              </div>
              <div *ngIf="!isMobile">  
                  {{row[cellData.def]}}
              </div>
            </ng-container>

            <ng-container *ngIf="cellData.def === 'type'">
              <div *ngIf="isMobile" fxLayout="row">
                <span><strong>{{cellData.label}}: </strong></span> &nbsp;
                <span>{{paymentService.getPaymentName(row.entityType, row.type)}}</span>
              </div>
              <div *ngIf="!isMobile">
                {{paymentService.getPaymentName(row.entityType, row.type)}}
              </div>
            </ng-container>

            <ng-container *ngIf="cellData.def === 'status'">
              <div *ngIf="isMobile" fxLayout="row">
                <span><strong>{{cellData.label}}: </strong></span> &nbsp;
                <span>{{row.status}} &nbsp; <mat-icon *ngIf="row.paid">done</mat-icon></span>
              </div>
              <div *ngIf="!isMobile">
                {{row.status}} &nbsp; <mat-icon *ngIf="row.paid">done</mat-icon>
              </div>
            </ng-container>

            <ng-container *ngIf="cellData.def === 'actions'">              
              <button mat-icon-button color="warn" aria-label="Delete Payment" *ngIf="allowDelete" (click)="deletePayment(row.id, row.type); $event.stopPropagation()" [disabled]="!editingPayment">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-raised-button type="button" *ngIf="!row.paid" color="primary" aria-label="Mark as Cleared" matTooltip="Mark as paid" (click)="markAsCleared(row); $event.stopPropagation()" [disabled]="!editingPayment">
                Mark as Cleared
              </button>              
            </ng-container>
          </mat-cell>  
        </ng-container>
     
        <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="paymentColumns"></mat-header-row>
        <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editingPayment? editPayment(row) : ''; $event.stopPropagation()" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: paymentColumns"></mat-row>
      </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 50, payments.length]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
    </div>


  </mat-card-content>
</mat-card>
