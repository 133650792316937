import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "app/auth/auth.service";
import { IPersonApplicationCertificate } from "app/models/People/person-application-certificate";
import { IAddress } from "app/models/common/address";
import { SharedService } from "app/services/core/shared.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-print-certificate',
  templateUrl: './print-certificate.component.html',
  styleUrls: ['./print-certificate.component.scss']
})

export class PrintCertificateComponent implements OnInit {
  public isMobile: boolean = false;
  
  public personCertificateModel: IPersonApplicationCertificate = {
    id: 0,
    firstName: "",
    lastName: "",
    codeType: "",
    issueDate: "",
    expirationDate: "",
    certicationNumber: ""
  };
  public personAddress: IAddress = {
    id: 0,
    street: "",
    city: "",
    state: "",
    zip: "",
    county: "",
    contactType: ""
  };
  public firmName: string = "";
  public loggedUser : string = this.username();
  public selectedCertificates: boolean = false;
  public selectedBadges: boolean = false;
  public selectedLetters: boolean = false;
  public badgeImageUrl: string | ArrayBuffer | null = null;    

  constructor(public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data:{ person:any, address:any, firmName:string, selectedCertificates: boolean, selectedBadges: boolean, selectedLetters: boolean },
    private breakpointObserver: BreakpointObserver,
    private toastr: ToastrService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<PrintCertificateComponent>
  ) { 
      this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
    });
  }

  username(): string {
    return this.authService.username;
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.badgeImageUrl = reader.result; // Set the badge image URL
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        this.toastr.error('Error reading file');
      };
      reader.readAsDataURL(file);
    }
  }

  ngOnInit(): void {
    if(this.data.person){
      this.personCertificateModel.id = this.data.person.person.id;
      this.personCertificateModel.firstName = this.data.person.person.firstName;
      this.personCertificateModel.lastName = this.data.person.person.lastName;
      this.personCertificateModel.codeType = this.data.person.codeType;
      this.personCertificateModel.issueDate = this.data.person.issueDate;
      this.personCertificateModel.expirationDate = this.data.person.expirationDate;
      this.personCertificateModel.certicationNumber = this.data.person.person.id + " - Indv - " + this.getCodeType(this.data.person.codeType);
      this.personAddress = this.data.address;
      this.firmName = this.data.firmName;
      this.selectedCertificates = this.data.selectedCertificates;
      this.selectedBadges = this.data.selectedBadges; 
      this.selectedLetters = this.data.selectedLetters;
    }
  }

  getCodeType(codeType: string): string {
    return codeType === "Supervisor" ? "S" : (codeType === "Risk Assesor" || codeType === "Risk Assessor") ? "R" : codeType === "Inspector" ? "I" : "W";
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }
  
  saveKendoPdf(pdf: any, fileName: string): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        pdf.saveAs(fileName);
        resolve();
      }, 1000); // Delay for 1000ms before exporting each pdf otherwise error when saving multiple files with the Badge
    });
  };

  async exportToPdf(pdfCert, pdfBadge, pdfLetter): Promise<void> {
    if (this.selectedBadges && !this.badgeImageUrl) {
      this.toastr.error('Please upload an image for the badge');
      return;
    }

    if (this.selectedBadges) {
      await this.saveKendoPdf(pdfBadge, this.personCertificateModel.lastName + ' ' + this.personCertificateModel.firstName + '_Badge' + '.pdf');
    }
    if (this.selectedLetters) {
      await this.saveKendoPdf(pdfLetter, this.personCertificateModel.certicationNumber + '_Letter' + '.pdf');
    }
    if (this.selectedCertificates) {
      await this.saveKendoPdf(pdfCert, this.personCertificateModel.certicationNumber + '_Certificate' + '.pdf');
    }
  }
}
