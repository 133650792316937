<!-- <div *ngIf="loading">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div> -->

<form #form="ngForm" [formGroup]="paymentService.searchForm" class="form-field-full">
  <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
    <div fxFlex="50.00" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
      <h2 class="noMarginHeader">
        <mat-icon>attach_money</mat-icon> Search Payments
      </h2>
    </div>
  </div>
  <div fxLayout="row wrap" class="matero-row">
    <div fxFlex="100.00" fxFlex.lt-sm="100" class="matero-col">
      <mat-card>
        <div fxLayout="row wrap" fxLayoutGap="8px grid">
          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field appearance="standard">
              <mat-label>Search Term</mat-label>
              <input id="searchTerm" name="searchTerm" formControlName="searchTerm" matInput />
            </mat-form-field>
          </div>

          <div fxFlex="50" fxFlex.lt-sm="100">
            <mat-form-field appearance="standard">
              <mat-label>Payment Type</mat-label>
              <input type="text" placeholder="Payment Type" id="paymentType" matInput formControlName="paymentType" name="paymentType" [matAutocomplete]="auto" (input)="paymentTypeFilter($event)">
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayPaymentOptions">
                <mat-option *ngFor="let option of filteredPaymentTypeOptions" [value]="option">
                  {{ option.Text }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="8px grid">
          <div fxFlex="50" fxFlex.lt-sm="100">
          <mat-form-field appearance="standard">
            <mat-label>Payment Status</mat-label>
            <mat-select id="status" name="status" formControlName="status">
              <mat-option value=""></mat-option>
              <mat-option *ngFor="let option of paymentService.paymentStatuses" value="{{option}}">{{option}}</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
        </div>

        <!-- buttons -->
        <div fxLayout="row wrap" fxLayoutGap="8px grid">
          <div fxFlex="100" fxFlex.lt-sm="100" fxLayoutAlign="start center">
            <br /><br /><br /><br />
            <button type="submit" id="submitButtonSearch" (click)="onSubmit()" mat-raised-button color="primary"
            title="Search">Search</button>
            &nbsp;&nbsp;
            <button type="submit" id="submitButtonReset" (click)="reset()" mat-raised-button color="basic" title="Clear">
              Clear
            </button>
            &nbsp;&nbsp;
          </div>
        </div>  
      </mat-card>
    </div>
  </div>
</form>

<br />
<!-- Results -->
<mat-card [style.background]="cardColor">
  <mat-card-content>
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between center">
        <span fxFlex="33%" style="font-size: 24px; font-weight: 500">Payments ({{payments.length}}) </span>
        <span fxFlex="33%" fxLayout="row" fxLayoutAlign="center center" *ngIf="loading">
          <mat-progress-spinner mode="indeterminate" diameter="24"></mat-progress-spinner>
          <span style="margin-left: 8px;">Loading...</span>
        </span>
        <span fxFlex="33%" style="text-align: right;" class="f-s-14">Click Entry to Edit </span>
      </div>

      <mat-table [dataSource]="paymentsSource" matSort #TableSort="matSort" aria-describedby="owners" class="mat-elevation-z8" >
        <ng-container *ngFor="let cellData of paymentColumnDefinitions; let i = index" [matColumnDef]="cellData.def">
          <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.width" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.label}}</mat-header-cell>
          <mat-cell *matCellDef="let row" [fxFlex]="cellData.width" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.def]}}">
            <ng-container *ngIf="cellData.def !== 'type' && cellData.def !== 'status' && cellData.def !== 'actions' && cellData.def !== 'entity'">
              <div *ngIf="isMobile" fxLayout="row">  
                <span><strong>{{cellData.label}}: </strong></span> &nbsp;
                {{row[cellData.def]}}
              </div>
              <div *ngIf="!isMobile">  
                {{row[cellData.def]}}
              </div>
            </ng-container>

            <ng-container *ngIf="cellData.def === 'entity'">
              <div *ngIf="isMobile" fxLayout="row">
                <span><strong>{{cellData.label}}: </strong></span> &nbsp;
                <a (click)="editEntity(row); $event.stopPropagation()">
                  <span>{{ row.entityDescription }} </span>
                  <span *ngIf="row.entityDescription == null || row.entityDescription.length == 0">[ID: {{row.entityId}}]</span>
                </a>
              </div>
              <div *ngIf="!isMobile">
                <a (click)="editEntity(row); $event.stopPropagation()">
                  <span>{{ row.entityDescription }} </span>
                  <span *ngIf="row.entityDescription == null || row.entityDescription.length == 0">[ID: {{row.entityId}}]</span>
                </a>
              </div>
            </ng-container>

            <ng-container *ngIf="cellData.def === 'type'">
              <div *ngIf="isMobile" fxLayout="row">
                <span><strong>{{cellData.label}}: </strong></span> &nbsp;
                <span>{{paymentService.getPaymentName(row.entityType, row.type)}}</span>
              </div>
              <div *ngIf="!isMobile">
                {{paymentService.getPaymentName(row.entityType, row.type)}}
              </div>
            </ng-container>

            <ng-container *ngIf="cellData.def === 'status'">
              <div *ngIf="isMobile" fxLayout="row">
                <span><strong>{{cellData.label}}: </strong></span> &nbsp;
                <span>{{row.status}} &nbsp; <mat-icon *ngIf="row.paid">done</mat-icon></span>
              </div>
              <div *ngIf="!isMobile">
                {{row.status}} &nbsp; <mat-icon *ngIf="row.paid">done</mat-icon>
              </div>
            </ng-container>

            <ng-container *ngIf="cellData.def === 'actions'">              
              <button mat-raised-button type="button" *ngIf="!row.paid" color="primary" aria-label="Mark as Cleared" matTooltip="Mark as paid" (click)="markAsCleared(row); $event.stopPropagation()" [disabled]="!editingPayment">
                Mark as Cleared
              </button>              
            </ng-container>
          </mat-cell>
        </ng-container>

        <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="paymentColumns"></mat-header-row>
        <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editingPayment? editPayment(row) : ''; $event.stopPropagation()" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: paymentColumns"></mat-row>        
      </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 50, payments.length]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
    </div>


  </mat-card-content>
</mat-card>
