import { Component, OnInit, ViewChild, } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { PaymentService } from '../../services/payments.service';
import { MatTableDataSource } from '@angular/material/table';
import { IPayment } from '../../models/payment';
import { DialogEditPaymentComponent } from '../edit/edit-payment-dialog';
import { MatDialog } from '@angular/material/dialog';
import { DialogMarkPaidComponent } from '../edit/mark-paid';
import { AddFirmApplicationComponent } from 'app/components/application/firm/add-firm-application/add-firm-application.component';
import { FirmAddEditComponent } from 'app/components/firm/add-edit/firm-add-edit.component';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { AtpService } from 'app/services/atp/atp.service';
import { AddIndividualApplicationComponent } from 'app/components/application/individual/add-individual-application/add-individual-application.component';
import { AddEditIndividualComponent } from 'app/components/individual/add-edit-individual/add-edit-individual.component';
import { AddEditAtpComponent } from 'app/components/atp/add-edit-atp/add-edit-atp.component';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AddEditAtpCourseComponent } from 'app/components/atp/add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-payment-dashboard',
  templateUrl: './payment-dashboard.component.html',
  styleUrls: ['./payment-dashboard.component.scss'],
  styles: [],  
  providers: [],
})
export class PaymentDashboardComponent implements OnInit {
  public environment = environment;

  public loading: boolean = true;  
  public isMobile: boolean = false;
  public paymentsSource = new MatTableDataSource<IPayment>();
  public paymentTypeOptions: any[] = [];
  public filteredPaymentTypeOptions: any[] = [];
  public payments: IPayment [] = [];
  public paymentColumns: string[] = ['entity','type','status','paymentCleared','amount','paymentMethod', 'orderNumber', 'comment', 'actions'];
  public paymentColumnDefinitions = [
    { def: 'entity', label: 'Entity', width: '20%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'orderNumber', label: 'Order Number', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'type', label: 'Type', width: '15%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'status', label: 'Status', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'paymentCleared', label: 'Payment Cleared', width: '10%', hide: false, align: 'left', type: 'boolean', sortable: true },
    { def: 'amount', label: 'Amount', width: '10%', hide: false, align: 'right', type: 'number', sortable: true },
    { def: 'paymentMethod', label: 'Payment Method', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'comment', label: 'Comments', width: '10%', hide: false, align: 'left', type: 'text', sortable: true },
    { def: 'actions', label: '', width: '15%', hide: false, align: 'center', type: 'actions', sortable: false }
  ];
  public editingPayment:boolean = true;
  public cardColor: string = '';
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  public pageSize = 10;  

  constructor(
    public sharedService: SharedService,
    public authService: AuthService,
    private dialog: MatDialog,
    public paymentService: PaymentService,
    public firmService: FirmService,
    public personService: PersonService,
    public atpService: AtpService,
    public toastr: ToastrService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.paymentTypeOptions = Array.from(
      this.paymentService.paymentOptions.reduce((map, option) => {
        map.set(option.Text, option);
        return map;
      }, new Map<string, any>()).values()
    );
    this.filteredPaymentTypeOptions = this.paymentTypeOptions;
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {    
    this.onSubmit();
  }

  loadData(): void {
    this.onSubmit();
  }

  paymentTypeFilter(event: any) {
    var value = event.target.value.toLowerCase();
    if (event.target.value.length < 1) {
      this.filteredPaymentTypeOptions = this.paymentTypeOptions;
    }
    else{      
      this.filteredPaymentTypeOptions = this.paymentTypeOptions.filter(payment => payment.Text.toLowerCase().includes(value));
    }          
  }

  displayPaymentOptions(option: any): string {    
    if (option) {
        return option.Text;
    } else {
        return '';
    }
  }

  onFilterChanged() : void { 
  }

  onSubmit(): void {
    this.paymentService.paymentSearch.entityId = this.paymentService.searchForm.value.entityId;
    this.paymentService.paymentSearch.entityType = this.paymentService.searchForm.value.entityType;
    this.paymentService.paymentSearch.searchTerm = this.paymentService.searchForm.value.searchTerm;
    this.paymentService.paymentSearch.status = this.paymentService.searchForm.value.status;
    this.paymentService.paymentSearch.paymentType = this.paymentService.searchForm.value.paymentType?.FeeType;
    this.loading = true;

    this.paymentService.search(this.paymentService.paymentSearch).subscribe(
      result => {
        this.payments = result;
        this.loading = false;
      },
      error => {
        this.toastr.error('Error loading payments', error);
      },
      () => {
        this.paymentsSource = new MatTableDataSource<IPayment>(this.payments);
        this.paymentsSource.paginator = this.paginator;
        this.paymentsSource.sort = this.sort;
      }
    );
  }

  reset(): void {
    this.paymentService.searchForm.reset();
  }

  editEntity(payment: IPayment) {
    if(payment.entityType === this.paymentService.FirmApplicationEntityType) {
      this.firmService.getFirmApplicationById(+payment.entityId).subscribe(
        result => {
          const dialogRef = this.dialog.open(AddFirmApplicationComponent, {
              width: '90%',
              data: {application: result, edit: true},
              autoFocus: false,
              panelClass: this.sharedService.darkMode ? "theme-dark" : ""
            });
            dialogRef.afterClosed().subscribe(result => {
              this.loadData();
            });
        },
        error => {
          this.toastr.error('Error loading firm application', error);
        });      
    }
    else if(payment.entityType === this.paymentService.FirmEntityType) {
     this.firmService.getFirm(+payment.entityId).subscribe(
      result => {
        const dialogRef = this.dialog.open(FirmAddEditComponent, {
        width: '90%',
        data: result,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {
          this.loadData();
        });
      },
      error => {
        this.toastr.error('Error loading firm', error);
      });  
 
    }
    else if(payment.entityType === this.paymentService.IndividualApplicationEntityType) {
      this.personService.getPersonApplicationById(+payment.entityId).subscribe(
        result => {
          const dialogRef = this.dialog.open(AddIndividualApplicationComponent, {
            width: '90%',
            data: {application: result, edit: true},
            autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
          });
          dialogRef.afterClosed().subscribe(result => {
              this.loadData();
          });
        },
        error => {
          this.toastr.error('Error loading individual application', error);
        });
    }
    else if(payment.entityType === this.paymentService.IndividualEntityType) {

      this.personService.getPersonById(+payment.entityId).subscribe(
        result => {
          const dialogRef = this.dialog.open(AddEditIndividualComponent, {
            width: '90%',
            data: {person: result},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
          });
          dialogRef.afterClosed().subscribe(result => {  
            this.loadData();    
          });
        },
        error => {
          this.toastr.error('Error loading individual', error);
        });     
    }
    else if(payment.entityType === this.paymentService.AtpEntityType) {
      this.atpService.getATPById(+payment.entityId).subscribe(
        result => {
          const dialogRef = this.dialog.open(AddEditAtpComponent, {
            width: '90%',
            data: {atp: result},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
          });
          dialogRef.afterClosed().subscribe(result => {  
            this.loadData();    
          });
        },
        error => {
          this.toastr.error('Error loading ATP', error);
        });      
    }
    else if(payment.entityType === this.paymentService.ATPAccreditationEntityType) {
      this.atpService.getATPByAccreditationId(+payment.entityId).subscribe(
        result => {
          const dialogRef = this.dialog.open(AddEditAtpComponent, {
            width: '90%',
            data: {atp: result},
            panelClass: this.sharedService.darkMode ? "theme-dark" : ""
          });
          dialogRef.afterClosed().subscribe(result => {  
            this.loadData();    
          });
        },
        error => {
          this.toastr.error('Error loading ATP', error);
        });      
    }
    else if(payment.entityType === this.paymentService.AtpCourseEntityType) {
      this.atpService.getATPCourseById(+payment.entityId).subscribe(
        result => {
          let course = result;
          this.atpService.getATPPeople(course.atp.id).subscribe(result=>{        
            let atpStaff = result.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));
            const dialogRef = this.dialog.open(AddEditAtpCourseComponent, {        
              data: {course:course, atp:course.atp, atpStaff: atpStaff},
              panelClass: this.sharedService.darkMode ? "theme-dark" : "",
              width: '90%',
              autoFocus: false
            });
            dialogRef.afterClosed().subscribe(result => {  
              this.loadData();  
            }); 
          },error=>{
            this.toastr.error("Error loading ATP Course Staff: ", error);
          })
        },
        error => {
          this.toastr.error('Error loading ATP Course', error);
        });      
    }
  }

  editPayment(payment: IPayment) {
    const dialogRef = this.dialog.open(DialogEditPaymentComponent, {
      data: payment,
      minWidth: !this.sharedService.mobile ? '700px' : '300px',
      maxWidth: !this.sharedService.mobile ? '800px' : '300px',
      maxHeight: !this.sharedService.mobile ? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.loadData();
      }
    });
  }

  markAsCleared(payment: IPayment) {
    const dialogRef = this.dialog.open(DialogMarkPaidComponent, {
      data: payment,
      minWidth: !this.sharedService.mobile? '700px' : '300px',
      maxWidth: !this.sharedService.mobile? '800px' : '300px',
      maxHeight: !this.sharedService.mobile? '1000px' : '500px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (!this.sharedService.isCancelled(response)) {
        this.loadData();
      }
    });
  }

}
