<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
        <h1>Loading</h1>
    </div>
</div>

<div fxLayout="column">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
            <mat-card [style.background-color]="uiData.atp.color" fxFlex="100">
                <h2 class="noMarginHeader">
                    <mat-icon>school</mat-icon> {{'Accredited Training Provider Management'}}
                </h2>
            </mat-card>
        </div>
    </div>

    <mat-card>
        <div [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
                <div fxLayoutGap="5px">
                    <strong class="noMarginHeader">Results ({{filteredTrainingProviders.length}})</strong>
                
                    <mat-form-field style="width:200px;">
                        <input (keyup)="filterTable()" placeholder="Filter id or name" [(ngModel)]="filterString" matInput/>                    
                    </mat-form-field>            
                    
                    <mat-form-field style="width:200px">
                        <mat-select placeholder="Filter by Inactive Status" [formControl]="filteredActive" (selectionChange)="filterTable()">
                            <mat-option *ngFor="let status of getFirmActiveTypes()" [value]="status">
                                {{ status }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                    
                    <mat-form-field style="width:220px">
                        <mat-select multiple placeholder="RRP Accreditation Status" [formControl]="filterRRPAccreditationStatus" (selectionChange)="filterTable()">
                            <mat-option *ngFor="let status of accreditationStatusOptions" [value]="status">
                            {{ status }}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>            
    
                    <mat-form-field style="width:220px">
                        <mat-select multiple placeholder="LBPA Accreditation Status" [formControl]="filterLBPAccreditationStatus" (selectionChange)="filterTable()">
                            <mat-option *ngFor="let status of accreditationStatusOptions" [value]="status">
                            {{ status }}
                        </mat-option>
                        </mat-select>
                    </mat-form-field> 
                    
                    <button mat-raised-button (click)="clearFilters()">Reset Filters</button>
                </div>
                                
                <div fxLayoutAlign="end">
                    <button mat-raised-button color="primary" title="Add Individual Application" (click)="editATP(null)">
                      <mat-icon>add</mat-icon> Add Accredited Training Provider
                    </button>                
                </div>
            </div>
            <br />

            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of individualApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                        <ng-container *ngIf="cellData.type === 'date'">
                            {{row[cellData.columnName] | date:'MM/dd/yyyy'}}
                        </ng-container>
                        <ng-container *ngIf="cellData.type === 'boolean'">
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                            {{displayLimit(row[cellData.columnName], cellData.size)}}
                        </ng-container>
                    </mat-cell>
                </ng-container>                    
              
                <!-- Button column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row" fxFlex="7">
                        <div style="display: flex; justify-content: space-between; width: 100px;">
                            <button mat-icon-button color="primary" style="width: 50px;" (click)="editATP(row); $event.stopPropagation()">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="individualApplicationTable.layout.columns.concat('actions')"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editATP(row); $event.stopPropagation()" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: individualApplicationTable.layout.columns.concat('actions')"></mat-row>
            </mat-table>
              
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>              
    </mat-card>   
</div>
