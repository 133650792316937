import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "app/auth/auth.service";
import { IAddress } from "app/models/common/address";
import { SharedService } from "app/services/core/shared.service";
import { FirmService } from "app/services/firm/firm-service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-print-firm-certificate',
  templateUrl: './print-firm-certificate.component.html',
  styleUrls: ['./print-firm-certificate.component.scss']
})

export class PrintFirmCertificateComponent implements OnInit {
    public isMobile: boolean = false;
    
 
    public personAddress: IAddress = {
      id: 0,
      street: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      contactType: ""
    };
    public firmName: string = "";
    public loggedUser : string = this.username();
    public selectedCertificates: boolean = false;
    public selectedLetters: boolean = false;
  firmId: any;
  contactName: any;
  codeTypeName: string;
  expDate: any;
  todayDate: string;
  issueDate: any;

  constructor(public sharedService: SharedService,
    private breakpointObserver: BreakpointObserver,
    private toastr: ToastrService,
    private authService: AuthService,
    private firmService: FirmService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<PrintFirmCertificateComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{ codeType:any, firmName:any,  firmAddress: any, firmId:any, expDate:any, issueDate:any, contactName:any, selectedCertificates: boolean, selectedLetters: boolean }) { 
        this.breakpointObserver.observe([
            Breakpoints.Handset,
            Breakpoints.Tablet,
            Breakpoints.Small,
          ]).subscribe(result => {
            this.isMobile = result.matches;
          });
    }

    username(): string {
      return this.authService.username;
    }


    ngOnInit(): void {
     
      this.firmName = this.data.firmName;
      this.firmId = this.data.firmId;      
      this.firmService.getFirmPeople(this.data.firmId).subscribe((result) => {
        var contact = result.filter(x => x.isPOC)[0].person;
        this.contactName = contact.firstName + ' ' + contact.lastName;
      }, (error) => {this.toastrService.error('Error getting firm people: ' + error.message);});
      this.personAddress = this.data.firmAddress;
      this.expDate = this.data.expDate;
      this.issueDate = this.data.issueDate;
      this.codeTypeName = this.getCodeType(this.data.codeType);
      this.todayDate =  new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        this.selectedCertificates = this.data.selectedCertificates;
        this.selectedLetters = this.data.selectedLetters;
   
    }

    getCodeType(codeType: string): string {
      return codeType === "LBP Firm" ? "Lead Based Paint Activities" :  "Renovation, Repair & Painting";
    }

    cancel(): void {
      this.dialogRef.close('cancel');
    }

    exportToPdf(pdfCert, pdfLetter ): void {     
      if(this.selectedCertificates){
        pdfCert.saveAs(this.firmId+ '_Certificate' +'.pdf');
      }
      if(this.selectedLetters){
        pdfLetter.saveAs(this.firmId+ '_Letter' +'.pdf');
    }
   
  }
}
