import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { IFirm } from 'app/models/firms/firm';
import { FirmService } from 'app/services/firm/firm-service';
import { FirmAddEditComponent } from './add-edit/firm-add-edit.component';
import { FormControl } from '@angular/forms';
import { FIRM_SERVICES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss',]
})

export class FirmComponent implements OnInit {
  public isMobile: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public filterString: string = '';
  public filteredFirms = [];  
  public filteredActive = new FormControl('Both');
  public filteredFirmType = new FormControl('Both');
  public expirationStartDate = new FormControl("");
  public expirationEndDate = new FormControl("");

  processing: boolean = false;
  loading: boolean = false;
  pageSize = 50;  
  firms: IFirm[] = []
  
  public dataSource: MatTableDataSource<any>;
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  firmTable = {        
    layout:{
      columns:['id', 'inactive', 'name', 'ccb', 'ccbExpirationDate','certificationNumber', 'servicesAvailable', 'isPrivate','hasLbpCert','hasRrpCert'],
      container:[
        {displayName:'Firm Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'8'},
        {displayName:'Firm Name',columnName:'name', type:'string', size:'11'},        
        {displayName:'CCB',columnName:'ccb', type:'string', size:'10'},          
        {displayName:'CCB Expiration Date',columnName:'ccbExpirationDate', type:'date', size:'10'},     
        {displayName:'Certification Number',columnName:'certificationNumber', type:'string', size:'10'},          
        {displayName:'Services Available',columnName:'servicesAvailable', type:'string', size:'10'},                            
        {displayName:'Private Firm',columnName:'isPrivate', type:'boolean', size:'8'},
        {displayName:'LBP Firm',columnName:'hasLbpCert', type:'boolean', size:'8'},
        {displayName:'RRP Firm',columnName:'hasRrpCert', type:'boolean', size:'8'},
      ],
      data: []      
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public commonService: CommonDataService, 
    private firmService: FirmService,
    private toastr: ToastrService
  ) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.loadFirms();
  }

  filterTable() { 
    this.commonService.firmManagementFilter.filterString = this.filterString;
    this.commonService.firmManagementFilter.filteredActive = this.filteredActive;
    this.commonService.firmManagementFilter.expirationStartDate = this.expirationStartDate;
    this.commonService.firmManagementFilter.expirationEndDate = this.expirationEndDate;
    this.commonService.firmManagementFilter.filteredFirmType = this.filteredFirmType;

    this.filteredFirms = this.firms.filter(x=>x.name.toLowerCase().includes(this.filterString.trim().toLowerCase()) || 
      x.ccb.toLowerCase().includes(this.filterString.trim().toLowerCase()) ||
      x.certificationNumber.toLowerCase().includes(this.filterString.trim().toLowerCase()) || 
      x.id.toString().includes(this.filterString.trim().toLowerCase())
    );        

    if (this.filteredActive.value !== 'Both'){
      this.filteredFirms = this.filteredFirms.filter(x=>x.inactive === (this.filteredActive.value === 'InActive') ? true : false);
    } 
    
    if (this.filteredFirmType.value !== 'Both'){
      if(this.filteredFirmType.value === 'LBP Firm'){
        this.filteredFirms = this.filteredFirms.filter(x=>x.hasLbpCert === true);
      }
      else{
        this.filteredFirms = this.filteredFirms.filter(x=>x.hasRrpCert === true);
      }      
    } 

    if(this.expirationStartDate.value !== null && this.expirationStartDate.value !== ""){      
      this.filteredFirms = this.filteredFirms.filter(x=> x.ccbExpirationDate !== null && x.ccbExpirationDate !== "" && new Date(x.ccbExpirationDate) >= new Date(this.expirationStartDate.value));
    }

    if(this.expirationEndDate.value !== null && this.expirationEndDate.value !== ""){      
      this.filteredFirms = this.filteredFirms.filter(x=> x.ccbExpirationDate !== null && x.ccbExpirationDate !== "" && new Date(x.ccbExpirationDate) <= new Date(this.expirationEndDate.value));
    }

    this.updateDataSource();
  }

  loadFirms() {
    this.loading = true;
    this.filterString = this.commonService.firmManagementFilter.filterString;
    this.filteredActive = this.commonService.firmManagementFilter.filteredActive;
    this.expirationStartDate = this.commonService.firmManagementFilter.expirationStartDate;
    this.expirationEndDate = this.commonService.firmManagementFilter.expirationEndDate;
    this.filteredFirmType = this.commonService.firmManagementFilter.filteredFirmType;

    this.firmService.getFirms().subscribe(
      result => {
        this.firms = result.sort((a, b) => b.id - a.id);        
        this.filteredFirms = this.firms;
        this.filterTable();
        this.loading = false;
      },
      error => {
        this.toastr.error(error.message);
        this.loading = false;
      },
    ); 
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredFirms);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editFirm(firm: IFirm) {    
    const dialogRef = this.dialog.open(FirmAddEditComponent, {
      width: '90%',
      data: firm,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  getServiceTypes(){
    return Object.values(FIRM_SERVICES);
  }

  getFirmActiveTypes(){
    return ['Active','InActive','Both'];
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  clearFilters(){
    this.filterString = '';
    this.filteredActive.setValue('Both');
    this.expirationStartDate.setValue('');
    this.expirationEndDate.setValue('');
    this.filteredFirmType.setValue('Both');

    this.filterTable();
  }
}
